import { Box, Grid, Typography } from "@mui/material";
import { FC } from "react";
import { themes } from "../../config/Icons";
import CardEquipo from "../../components/Card/CardEquipo";
import { useNavigate } from "react-router-dom";
import SolucionesEquipo from "./SolucionesEquipo";

interface Props {
  isDarkMode?: Boolean;
}

const Equipos: FC<Props> = ({ isDarkMode }) => {
  const themeMode = isDarkMode ? themes.dark : themes.light;
  const navigate = useNavigate();

  // Datos de las tarjetas
  const cardData = [
    {
      image: themeMode.imagen.impresoraAMC,
      title: "Impresora AMC A6",
      description:
        "Ideal para un entorno de pago concurrido donde se requiere una impresión de gran volumen.",
      precio: "$182.90",
      descuento: "$200.00",
    },
    {
      image: themeMode.imagen.allInOneAMC1002,
      title: "ALL-IN-ONE AMC 1002 WP",
      description:
        "Ideal para un entorno de pago concurrido donde se requiere una impresión de gran volumen.",
      precio: "$182.90",
      descuento: "$200.00",
    },
    {
      image: themeMode.imagen.allInOneAMC1520,
      title: "ALL-IN-ONE AMC 1520",
      description:
        "Ideal para un entorno de pago concurrido donde se requiere una impresión de gran volumen.",
      precio: "$182.90",
      descuento: "$200.00",
    },
    {
      image: themeMode.imagen.totemAMC,
      title: "Totem AMC",
      description:
        "Ideal para un entorno de pago concurrido donde se requiere una impresión de gran volumen.",
      precio: "$182.90",
      descuento: "$200.00",
    },
    {
      image: themeMode.imagen.panelAMC,
      title: "Panel AMC",
      description:
        "Ideal para un entorno de pago concurrido donde se requiere una impresión de gran volumen.",
      precio: "$182.90",
      descuento: "$200.00",
    },
  ];

  const handleClick = (card: any) => {
    navigate("/productos/detalle", { state: { card } });
  };

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        flexDirection: "column",
        alignItems: "center",
        background: "#F8F9FC",
      }}
    >
      <Box
        sx={{
          display: "flex",
          paddingTop: "140px",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start",
          gap: "60px",
        }}
      >
        {/*titulo */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "24px",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Montserrat",
              fontSize: "44px",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "112%",
            }}
          >
            Equipos AMC
          </Typography>
          {/* Separador */}
          <Box component={"img"} src={themeMode.icons.separador} />
        </Box>
        {/*grib */}
        <Grid
          container
          spacing={2}
          sx={{
            maxWidth: "1200px",
          }}
        >
          {cardData.map((card, index) => (
            <Grid key={index} item xs={12} sm={4}>
              <CardEquipo
                image={card.image}
                title={card.title}
                description={card.description}
                precio={card.precio}
                descuento={card.descuento}
                handleOpen={() => {
                  handleClick(card);
                }}
              />
            </Grid>
          ))}
        </Grid>
        {/*soluciones */}
        <SolucionesEquipo />
      </Box>
    </Box>
  );
};

export default Equipos;
