import { FC, useState } from "react";
import { Box, IconButton } from "@mui/material";

interface Props {
  onClick: any;
  bgColor: any;
  hoverColor: any;
  iconName: string;
  width?: any;
  height?: any;
  visibility?: any;
  iconHcolor: any;
  iconNcolor: any;
  border?:any;
  ImageSize:any;
}

const ButtonCircular: FC<Props> = ({
  onClick,
  bgColor,
  hoverColor,
  iconName,
  width,
  height,
  visibility,
  iconHcolor,
  iconNcolor,
  border,
  ImageSize,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <IconButton
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      sx={{
        display: "flex",
        width: width,
        height: height,
        visibility: visibility,
        padding: "12px",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        borderRadius: "24px",
        backgroundColor: isHovered ? hoverColor : bgColor, // Cambia el fondo en hover
        boxShadow: "0px 2px 10px 0px rgba(30, 41, 71, 0.14)",
        border: border,
        cursor: "pointer",
        "&:hover": {
          backgroundColor: isHovered ? hoverColor : bgColor, // Asegúrate de que esto funcione
        },
      }}
    >
      <Box
        component={"img"}
        sx={{
          width: ImageSize,
          height: ImageSize,
          WebkitMaskImage: `url(${iconName})`,
          WebkitMaskSize: ImageSize,
          WebkitMaskRepeat: "no-repeat",
          WebkitMaskPosition: "center",
          backgroundColor: isHovered ? iconHcolor : iconNcolor, // Cambia el color del ícono
        }}
      />
    </IconButton>
  );
};

export default ButtonCircular;
