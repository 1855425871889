import { Box, Grid, Tab, Tabs, Typography } from "@mui/material";
import React, { FC, useState } from "react";
import { themes } from "../../config/Icons";
import ButtonText from "../../components/Buttones/ButtonText";
import CardBeneficios from "../../components/Card/CardBeneficios";
import CardModulos from "../../components/Card/CardModulos";
import CardComplementos from "../../components/Card/CardComplementos";

interface Props {
  isDarkMode?: Boolean;
}


const InfoFact: FC<Props> = ({ isDarkMode }) => {
  const themeMode = isDarkMode ? themes.dark : themes.light;
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const cardData = [
    {
      icon: themeMode.icons.usuario,
      title: "Software experto",
      description: "Mejora tus tiempos de producción y unifica los procesos.",
    },
    {
      icon: themeMode.icons.usuario,
      title: "Gestión óptima",
      description:
        "Centraliza la gestión de tu local y has de esta fácil y eficiente.",
    },
    {
      icon: themeMode.icons.configuracion,
      title: "Servicio técnico 24/7",
      description: "Apoyo constante de técnicos experimentados en INFOrest®.",
    },
  ];

  const cardModulos = [
    {
      image: themeMode.imagen.ADMReglasNegocio,
      title: "Administración y reglas de negocio",
      description:
        "100 caract máx. Lorem ipsum dolor sit amet con sec tetuer adipiscing elit, aenea ligula eget dolor. ",
    },
    {
      image: themeMode.imagen.ConsultaReporte,
      title: "Consulta y reportes",
      description:
        "100 caract máx. Lorem ipsum dolor sit amet con sec tetuer adipiscing elit, aenea ligula eget dolor. ",
    },
    {
      image: themeMode.imagen.PuntoVentaCaja,
      title: "Punto venta tradicional y caja rápida",
      description:
        "100 caract máx. Lorem ipsum dolor sit amet con sec tetuer adipiscing elit, aenea ligula eget dolor. ",
    },
    {
      image: themeMode.imagen.PuntoAdicion,
      title: "Punto adición",
      description:
        "100 caract máx. Lorem ipsum dolor sit amet con sec tetuer adipiscing elit, aenea ligula eget dolor. ",
    },
  ];

  const cardComplementos = [
    {
      image: themeMode.imagen.ConsolaAgregadores,
      title: "Consola de Agregadores",
      description:
        "100 caract máx. Lorem ipsum dolor sit amet con sec tetuer adipiscing elit, aenea ligula eget dolor. ",
    },
    {
      image: themeMode.imagen.CartaGourmet,
      title: "Carta Gourmet",
      description:
        "100 caract máx. Lorem ipsum dolor sit amet con sec tetuer adipiscing elit, aenea ligula eget dolor. ",
    },
    {
      image: themeMode.imagen.CajaWeb,
      title: "Caja Web",
      description:
        "100 caract máx. Lorem ipsum dolor sit amet con sec tetuer adipiscing elit, aenea ligula eget dolor. ",
    },
    {
      image: themeMode.imagen.ChefControl,
      title: "Chef Control",
      description:
        "100 caract máx. Lorem ipsum dolor sit amet con sec tetuer adipiscing elit, aenea ligula eget dolor. ",
    },
    {
      image: themeMode.imagen.ModulosKDS,
      title: "KDS",
      description:
        "100 caract máx. Lorem ipsum dolor sit amet con sec tetuer adipiscing elit, aenea ligula eget dolor. ",
    },
    {
      image: themeMode.imagen.ADMCentralizar,
      title: "Administración Centralizada",
      description:
        "100 caract máx. Lorem ipsum dolor sit amet con sec tetuer adipiscing elit, aenea ligula eget dolor. ",
    },
    {
      image: themeMode.imagen.Quiosco,
      title: "Qiosco",
      description:
        "100 caract máx. Lorem ipsum dolor sit amet con sec tetuer adipiscing elit, aenea ligula eget dolor. ",
    },
    {
      image: themeMode.imagen.CentralDelyvery,
      title: "Central Delivery",
      description:
        "100 caract máx. Lorem ipsum dolor sit amet con sec tetuer adipiscing elit, aenea ligula eget dolor. ",
    },
  ];

  const cardComplemento = [
    {
      image: themeMode.imagen.CartaDigital,
      title: "Carta Digital",
      description:
        "Permita a sus clientes la posibilidad de auto atenderse en una pantalla táctil, pagar su pedido y acercarse al mostrador sólo para el recojo; de esta manera optimiza sus tiempos y ventas.",
    },
    {
      image: themeMode.imagen.MobileInfores,
      title: "Mobile INFOrest",
      description:
        "Es un punto de adición web, con todas las funciones del módulo de escritorio. Utilizado con tablets Wi-Fi para que el mesero no pierda contacto con el cliente y envíe sus pedidos desde el mismo salón.",
    },
    {
      image: themeMode.imagen.Totem,
      title: "Totem Digital",
      description:
        "Permita a sus clientes la posibilidad de auto atenderse en una pantalla táctil, pagar su pedido y acercarse al mostrador sólo para el recojo; de esta manera optimiza sus tiempos y ventas.",
    },
  ];
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        flexDirection: "column",
        alignItems: "center",
        gap: "100px",
        background: "#F8F9FC",
      }}
    >
      {/*cabecera */}
      <Box
        sx={{
          display: "flex",
          width: "1200px",
          paddingTop: "120px",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {/*Frame 989 */}
        <Box
          sx={{
            display: "flex",
            width: "620px",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "60px",
            flexShrink: 0,
          }}
        >
          {/*Frame 988 */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "20px",
              alignSelf: "stretch",
            }}
          >
            {/*logo*/}
            <Box
              component={"img"}
              src={themeMode.logo.logoInfoFact}
              sx={{
                width: "272px",
                height: "72px",
              }}
            />
            <Typography
              sx={{
                alignSelf: "stretch",
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: 300,
                lineHeight: "28px",
              }}
            >
              Con {""}
              <Box component={"span"} sx={{ fontWeight: 500 }}>
                INFOfact® {""}
              </Box>
              tienes un software modular que te permitirá evaluar, gestionar,
              planificar e identificar indicadores de control, estadísticas de
              ventas, calidad en el servicio y programas de lealtad en su
              negocio de Alimentos y Bebidas con énfasis en Costos y
              Estandarización.
            </Typography>
          </Box>
          {/*boton texto */}
          <ButtonText
            height="56px"
            width="260px"
            onClick={() => {}}
            title="¡solicita una demo!"
            active={true}
            bgColor={"#4884E2"}
            hoverColor={"#69C4DE"}
            fontSize={15}
          />
        </Box>
        {/*IMAGEN */}
        <Box
          component={"img"}
          src={themeMode.imagen.hero1}
          sx={{
            width: "480px",
            height: "480px",
            flexShrink: 0,
          }}
        />
      </Box>

      {/*beneficios */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "40px",
        }}
      >
        {/*titulo */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "24px",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Montserrat",
              fontSize: "36px",
              fontWeight: 500,
              lineHeight: "120%",
            }}
          >
            Sus beneficios
          </Typography>
          {/* Separador */}
          <Box component={"img"} src={themeMode.icons.separador} />
        </Box>
        {/*Frame 210 */}
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            gap: "100px",
          }}
        >
          {/*BENEFICIOS */}
          {cardData.map((card, index) => (
            <CardBeneficios
              key={index}
              icon={card.icon}
              title={card.title}
              description={card.description}
            />
          ))}
        </Box>
      </Box>
      {/*modulos */}
      <Box
        sx={{
          display: "flex",
          width: "1200px",
          flexDirection: "column",
          alignItems: "center",
          gap: "40px",
        }}
      >
        {/*titulo */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "24px",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Montserrat",
              fontSize: "44px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "120%",
            }}
          >
            Lo que necesitas para destacar
          </Typography>
          {/* Separador */}
          <Box component={"img"} src={themeMode.icons.separador} />
        </Box>
        {/*Productos */}
        <Box
          sx={{
            display: "flex",
            width: "1200px",
            flexDirection: "column",
            alignItems: "center",
            gap: "28px",
          }}
        >
          {/*TAB */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              TabIndicatorProps={{
                sx: {
                  width: "100%",
                  height: "9px",
                  backgroundImage: `url(${themeMode.icons.tabFlecha})`,
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundColor: "transparent",
                  "& .Mui-selected": {
                    color: "var(--colorPrimary-Green) !important",
                  },
                },
              }}
              textColor="inherit"
              sx={{
                width: "100%",
              }}
            >
              <Tab
                label="Módulos"
                sx={{
                  backgroundColor: value === 0 ? "transparent" : "transparent",
                  fontFamily: "Montserrat",
                  color: value === 0 ? "#3677DE" : "#88919E",
                  fontSize: "20px",
                  fontWeight: 400,
                  padding: "20px",
                  flex: 1,
                  marginBottom: "8px",
                  textTransform: "none",
                }}
              />
              <Tab
                label="Complementos"
                sx={{
                  backgroundColor: value === 1 ? "transparent" : "transparent",
                  fontFamily: "Montserrat",
                  color: value === 1 ? "#3677DE" : "#88919E",
                  fontSize: "20px",
                  padding: "20px",
                  fontWeight: 400,
                  flex: 1,
                  marginBottom: "8px",
                  textTransform: "none",
                }}
              />
            </Tabs>
          </Box>

          {/*Contenido */}
          {value === 0 && (
            <Grid container spacing={2}>
              {cardModulos.map((card, index) => (
                <Grid key={index} item xs={12} md={6}>
                  <CardModulos
                    title={card.title}
                    Image={card.image}
                    width={"590px"}
                    height={"200px"}
                    descripcion={card.description}
                  />
                </Grid>
              ))}
            </Grid>
          )}

          {value === 1 && (
            <Grid container spacing={2}>
              {cardComplementos.map((card, index) => (
                <Grid
                  key={index}
                  xs={12}
                  sm={6}
                  md={4}
                  sx={{
                    paddingLeft: "16px",
                    paddingTop: "16px",
                  }}
                >
                  <CardModulos
                    title={card.title}
                    Image={card.image}
                    width={"387px"}
                    height={"200px"}
                    descripcion={card.description}
                  />
                </Grid>
              ))}
            </Grid>
          )}
        </Box>
      </Box>
      {/*popular */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "60px",
        }}
      >
        {/*titulo */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "24px",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Montserrat",
              fontSize: "36px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "120%",
            }}
          >
            ¡Los complementos más populares!
          </Typography>
          {/* Separador */}
          <Box component={"img"} src={themeMode.icons.separador} />
        </Box>

        {/*Cards */}
        {cardComplemento.map((card, index) => (
          <CardComplementos
            key={index}
            image={card.image}
            title={card.title}
            description={card.description}
            onButtonClick={() => {
              console.log("Botón clickeado");
            }}
          />
        ))}
      </Box>
      {/*Equipos */}

      <Box
        sx={{
          display: "flex",
          padding: "0px 0px 100px",
          flexDirection: "column",
          alignItems: "center",
          gap: "40px",
        }}
      >
        {/*seccion */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "24px",
          }}
        >
          <Typography
            sx={{
              textAlign: "center",
              fontFamily: "Montserrat",
              fontSize: "36px",
              fontWeight: 500,
              lineHeight: "120%",
            }}
          >
            Potencia tu negocio con los mejores equipos
          </Typography>
          {/*separador */}
          <Box component={"img"} src={themeMode.icons.separador} />
        </Box>
        {/*IMAGEN EQUIPO */}
        <Box
          component={"img"}
          src={themeMode.imagen.equipos}
          sx={{
            width: "1000px",
            height: "400px",
            alignSelf: "stretch",
            borderRadius: "20px",
          }}
        />
        {/*boton texto */}
        <ButtonText
          height="56px"
          width="320px"
          onClick={() => {}}
          title="descubre cómo innovar"
          active={true}
          bgColor={"#4884E2"}
          hoverColor={"#69C4DE"}
          fontSize={15}
        />
      </Box>
    </Box>
  );
};

export default InfoFact;
