import { Box, Typography } from "@mui/material";
import { FC } from "react";
import { themes } from "../../config/Icons";
import ButtonText from "../../components/Buttones/ButtonText";
import Trayectoria from "./Trayectoria";
import Principios from "./Principios";
import Presencia from "./Presencia";
import SolucionesEquipo from "../Equipos/SolucionesEquipo";

interface Props {
  isDarkMode?: Boolean;
}

const QuinesSomos: FC<Props> = ({ isDarkMode }) => {
  const themeMode = isDarkMode ? themes.dark : themes.light;
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        flexDirection: "column",
        alignItems: "center",
        background: "#F8F9FC",
      }}
    >
      {/*Frame 1277 */}
      <Box
        sx={{
          display: "flex",
          width: "1920px",
          flexDirection: "column",
          alignItems: "center",
          gap: "100px",
        }}
      >
        {/*Frame 1271 */}
        <Box
          sx={{
            display: "flex",
            width: "1200px",
            paddingTop: "140px",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "60px",
          }}
        >
          {/*titulo */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "24px",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Montserrat",
                fontSize: "44px",
                fontWeight: 700,
                lineHeight: "112%",
              }}
            >
              ¿Quiénes somos?
            </Typography>
            {/*separador */}
            <Box component={"img"} src={themeMode.icons.separador} />
          </Box>
          {/*IMAGEN */}
          <Box
            sx={{
              height: "480px",
              alignSelf: "stretch",
              borderRadius: "12px",
              background: `url(${themeMode.imagen.pedidosMoso})`,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          />
        </Box>
        {/*Frame 1275 */}
        <Box
          sx={{
            display: "flex",
            width: "1200px",
            alignItems: "center",
            gap: "60px",
          }}
        >
          <Typography
            sx={{
              width: "480px",
              flexShrink: 0,
              fontFamily: "Montserrat",
              fontSize: "36px",
              fontWeight: 500,
              lineHeight: "120%",
            }}
          >
            Tu socio estratégico en el mercado HORECA
          </Typography>
          <Typography
            sx={{
              flex: "1 0 0",
              fontFamily: "Poppins",
              fontSize: "16px",
              fontWeight: 300,
              lineHeight: "150%",
            }}
          >
            INFOmática es una corporación enfocada en plantear{""}
            <Box component={"span"} sx={{ fontWeight: 500 }}>
              {" "}
              soluciones {""}
            </Box>
            con probada experiencia para el mercado de hotelería, restaurantes y
            afines (HORECA). Tenemos una comunidad de clientes que nos acompaña
            por {""}
            <Box component={"span"} sx={{ fontWeight: 500 }}>
              {" "}
              más de 20 años {""}
            </Box>
            y que serán siempre nuestro mejor testimonio de fortaleza de marca,{" "}
            {""}
            <Box component={"span"} sx={{ fontWeight: 500 }}>
              {" "}
              excelencia de productos de software y servicios. {""}
            </Box>
          </Typography>
        </Box>
        {/*Frame 1273 */}
        <Box
          sx={{
            display: "flex",
            width: "1200px",
            alignItems: "center",
            gap: "60px",
          }}
        >
          {/*IMAGEN */}
          <Box
            sx={{
              width: "648px",
              height: "432px",
              flexShrink: 0,
              borderRadius: "12px",
              background: `url(${themeMode.imagen.bonBeff})`,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          />
          {/*Frame 1272 */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "36px",
              flex: "1 0 0",
            }}
          >
            {/*Frame 1276 */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "20px",
                alignSelf: "stretch",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Montserrat",
                  fontSize: "36px",
                  fontWeight: 500,
                  lineHeight: "120%",
                }}
              >
                La solución tecnológica que tu negocio necesita
              </Typography>
              <Typography
                sx={{
                  alignSelf: "stretch",
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: 300,
                  lineHeight: "150%",
                }}
              >
                Brindamos un servicio de alta calidad, con sentido de
                responsabilidad y en constante {""}
                <Box component={"span"} sx={{ fontWeight: 500 }}>
                  innovación; {""}
                </Box>
                anticipándonos a sus necesidades y aportando a su negocio con
                nuestras soluciones.
              </Typography>
            </Box>

            {/*boton texto */}
            <ButtonText
              height="56px"
              width="320px"
              onClick={() => {}}
              title="descubre cómo innovar"
              active={true}
              bgColor={"#4884E2"}
              hoverColor={"#69C4DE"}
              fontSize={15}
            />
          </Box>
        </Box>
        {/*trayectoria */}
        <Trayectoria />
        {/*principios */}
        <Principios />
        {/*presencia */}
        <Presencia />
      </Box>
      {/*soluciones */}
      <SolucionesEquipo />
    </Box>
  );
};

export default QuinesSomos;
