import { CssBaseline, ThemeProvider } from "@mui/material";
import { Suspense, useMemo, useState } from "react";
import getTheme from "../config/theme";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Body from "../content/Inicio/Body/Body";
import Equipos from "../content/Equipos/Equipos";
import DetallesEquipos from "../content/Equipos/DetallesEquipos";
import Soporte from "../content/Soporte/Soporte";
import IntranetLayout from "../components/Shared/IntranetLayout";
import Inicio from "../pages/Intranet/Inicio";
import Pagos from "../pages/Intranet/Pagos";
import InfoRest from "../content/Modulos/InfoRest";
import InfoHotel from "../content/Modulos/InfoHotel";
import InfoBack from "../content/Modulos/InfoBack";
import InfoFact from "../content/Modulos/InfoFact";
import QuinesSomos from "../content/QuiénesSomos/QuinesSomos";
import Layout from "../content/Inicio/Layout";
import { UserProvider } from "../contexts/UserContext";
import { SidebarProvider } from "../contexts/SidebarContext";

const index = () => {
  const [darkMode, setDarkMode] = useState(false);
  const theme = useMemo(() => getTheme(darkMode), [darkMode]);
  //activar el modo Dark
  const handleThemeChange = () => {
    setDarkMode((prevMode) => !prevMode);
  };

  return (
    <Suspense fallback={<></>}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <UserProvider>
            <SidebarProvider>
              <Routes>
                <Route path="/" element={<Layout isDarkMode={darkMode} />}>
                 <Route index element={<Body />} />
                  <Route path="/nosotros" element={<QuinesSomos />} />
                  <Route path="/productos" element={<Equipos />} />
                  <Route path="/productos/detalle" element={<DetallesEquipos />}
                  />
                  <Route path="/soporte" element={<Soporte />} />
                  <Route path="/inforest" element={<InfoRest />} />
                  <Route path="/infohotel" element={<InfoHotel />} />
                  <Route path="/infoback" element={<InfoBack />} />
                  <Route path="/infofact" element={<InfoFact />} />
                </Route>
                <Route path="intranet" element={<IntranetLayout />}>
                  <Route index element={<Inicio />} />
                  <Route path="pagos" element={<Pagos />} />
                </Route>
              </Routes>
            </SidebarProvider>
          </UserProvider>
        </BrowserRouter>
      </ThemeProvider>
    </Suspense>
  );
};

export default index;
