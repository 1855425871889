import { FC, useState } from "react";
import { themes } from "../../../../config/Icons";
import { Box, Typography } from "@mui/material";
import HoverCard from "./HoverCard";
import ButtonFlecha from "../../../../components/Buttones/ButtonFlecha";

interface Props {
  isDarkMode: Boolean;
}

const Nosotros: FC<Props> = ({ isDarkMode }) => {
  const themeMode = isDarkMode ? themes.dark : themes.light;
  const [isHovered, setIsHovered] = useState(false);
  return (
    <Box
      sx={{
        display: "flex",
        padding: "80px 0px",
        flexDirection: "column",
        alignItems: "center",
        gap: "100px",
        alignSelf: "stretch",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "60px",
        }}
      >
        {/*titulo */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "24px",
          }}
        >
          {/*Texto */}
          <Typography
            sx={{
              color: "#1E2947",
              textAlign: "center",
              fontFamily: "Montserrat",
              fontSize: "36px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "120%",
            }}
          >
            Conoce un poco más sobre nosotros
          </Typography>
          {/*separador */}
          <Box component={"img"} src={themeMode.icons.separador} />
        </Box>

        {/*cards */}
        <Box
          sx={{
            display: "flex",
            width: "1200px",
            flexDirection: "column",
            alignItems: "center",
            gap: "40px",
          }}
        >
          {/*Frame 891 */}
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              gap: "20px",
              alignSelf: "stretch",
            }}
          >
            {/*nosotros */}
            <HoverCard
              imageSrc={themeMode.imagen.experiencia}
              title="Nuestra experiencia"
              description="Llevamos más de 20 años en el mercado HORECA. ¿Quieres conocer nuestra historia?"
              buttonText="Una larga trayectoria"
              onClick={() => console.log("Botón clickeado")}
              buttonColor={themeMode.colors.Activo}
              iconName={themeMode.icons.flechaLinealDerecha}
              isDarkMode={isDarkMode}
            />
            <HoverCard
              imageSrc={themeMode.imagen.proyecto}
              title="Nuestros proyectos"
              description="Nuestro trabajo deja huella en el mercado gastronómico y hotelero. ¿Te interesa saber más?"
              buttonText="Lo que logramos"
              onClick={() => console.log("Botón clickeado")}
              buttonColor={themeMode.colors.Activo}
              iconName={themeMode.icons.flechaLinealDerecha}
              isDarkMode={isDarkMode}
            />
            <HoverCard
              imageSrc={themeMode.imagen.laboral}
              title="Oportunidades laborales"
              description="Siempre buscamos tener el mejor equipo. ¿Quieres formar parte de Infomática?"
              buttonText="Únete a la familia"
              onClick={() => console.log("Botón clickeado")}
              buttonColor={themeMode.colors.Activo}
              iconName={themeMode.icons.flechaLinealDerecha}
              isDarkMode={isDarkMode}
            />
          </Box>

          {/*Capacitaciones */}
          <Box
            sx={{
              display: "flex",
              height: "200px",
              alignItems: "center",
              alignSelf: "stretch",
              borderRadius: "20px",
              boxShadow: "0px 4px 16px 0px rgba(30, 41, 71, 0.12)",
              overflow: "hidden",
              position: "relative",
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            {/* Imagen */}
            <Box
              component="img"
              src={themeMode.imagen.capacitacion}
              sx={{
                borderRadius: "20px 0px 0px 20px",
                width: "460px",
                height: "200px",
                objectFit: "cover",
              }}
            />
            {/* Fondo negro (aparece/desaparece en hover) */}
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "460px",
                height: "200px",
                backgroundColor: "rgba(35, 35, 35, 0.5)",
                display: isHovered ? "none" : "flex",
                justifyContent: "center",
                alignItems: "center",
                transition: "opacity 0.3s",
              }}
            >
              <Typography
                sx={{
                  color: "#FFF",
                  fontFamily: "Montserrat",
                  fontSize: "24px",
                  fontWeight: 500,
                  textAlign: "center",
                }}
              >
                Capacitaciones certificadas
              </Typography>
            </Box>
            {/*info */}
            <Box
              sx={{
                display: "flex",
                padding: "20px",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
                gap: "20px",
                flex: "1 0 0",
                alignSelf: "stretch",
              }}
            >
              <Typography
                sx={{
                  alignSelf: "stretch",
                  color: "#717883",
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 300,
                  lineHeight: "150%",
                }}
              >
                Matricúlate en nuestras capacitaciones de INFOhotel® e INFOrest®
                con nuestros profesores certificados y equipos innovadores, para
                que aprendas en simulaciones de trabajo.
              </Typography>

              {/* Botón con Flecha */}
              <ButtonFlecha
                onClick={() => {}}
                textColor={themeMode.colors.Activo}
                textName={"Aprende con nosotros"}
                iconName={themeMode.icons.flechaLinealDerecha}
                bgColor={themeMode.colors.Activo}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Nosotros;
