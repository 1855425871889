import { Box, Typography } from "@mui/material";
import React, { FC } from "react";
import { themes } from "../../config/Icons";
interface Props {
  isDarkMode?: Boolean;
}

const Presencia: FC<Props> = ({ isDarkMode }) => {
  const themeMode = isDarkMode ? themes.dark : themes.light;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "40px",
        alignSelf: "stretch",
      }}
    >
      {/*SECCION */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "24px",
        }}
      >
        {/*titulo */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "24px",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Montserrat",
              fontSize: "36px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "120%",
            }}
          >
            Nuestra presencia internacional
          </Typography>
          {/* Separador */}
          <Box component={"img"} src={themeMode.icons.separador} />
        </Box>
        <Typography
          sx={{
            width: "1200px",
            textAlign: "center",
            fontFamily: "Poppins",
            fontSize: "20px",
            fontStyle: "normal",
            fontWeight: 300,
            lineHeight: "140%",
          }}
        >
          Lideramos el mercado HORECA gracias a la confianza de más de 1000
          clientes, con 4000 licencias vendidas en 4 países: {""}
          <Box
            component={"span"}
            sx={{
              fontWeight: 500,
            }}
          >
            Perú, Chile, Ecuador, Argentina, {""}
          </Box>
          y próximamente en {""}
          <Box
            component={"span"}
            sx={{
              fontWeight: 500,
            }}
          >
            España.
          </Box>
        </Typography>
      </Box>
      <Box 
      component={"img"}
       src={themeMode.icons.MapaMundo}
      sx={{
        width: "1920px",
        height: "800px",
      }}/>

    </Box>
  );
};

export default Presencia;
