import { Box, Typography } from "@mui/material";
import { FC } from "react";
import { themes } from "../../config/Icons";
import ButtonText from "../../components/Buttones/ButtonText";
import { PillTexto } from "../../components/Pills/PillTexto";
import SolucionesEquipo from "../Equipos/SolucionesEquipo";

interface Props {
  isDarkMode?: Boolean;
}

const Soporte: FC<Props> = ({ isDarkMode }) => {
  const themeMode = isDarkMode ? themes.dark : themes.light;
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        flexDirection: "column",
        alignItems: "center",
        background: "#F8F9FC",
      }}
    >
      {/*frame 1246 */}
      <Box
        sx={{
          display: "flex",
          width: "1200px",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "40px",
        }}
      >
        {/*Frame 1244 */}
        <Box
          sx={{
            display: "flex",
            paddingTop: "140px",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "40px",
            alignSelf: "stretch",
          }}
        >
          {/*titulo */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "24px",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Montserrat",
                fontSize: "44px",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "112%",
              }}
            >
              Soporte técnico
            </Typography>
            {/* Separador */}
            <Box component={"img"} src={themeMode.icons.separador} />
          </Box>
          {/*frame 1232 */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "40px",
              alignSelf: "stretch",
            }}
          >
            {/*IMAGEN */}
            <Box
              component={"img"}
              src={themeMode.imagen.soporte}
              sx={{
                width: "480px",
                height: "360px",
              }}
            />
            {/*frmae 1231 */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "48px",
                flex: "1 0 0",
              }}
            >
              {/*FRAME 1233 */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: "20px",
                  alignSelf: "stretch",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontSize: "24px",
                    fontWeight: 400,
                    lineHeight: "120%",
                  }}
                >
                  ¿Necesitas ayuda?
                </Typography>
                <Typography
                  sx={{
                    alignSelf: "stretch",
                    fontFamily: "Poppins",
                    fontSize: "16px",
                    fontWeight: 300,
                    lineHeight: "150%",
                  }}
                >
                  Ponte en
                  <Box component={"span"} sx={{ fontWeight: 500 }}>
                    {""} contacto
                  </Box>
                  {""} con nosotros o crea un {""}
                  <Box component={"span"} sx={{ fontWeight: 500 }}>
                    ticket de atención {""}
                  </Box>
                  para recibir soporte en tu sistema INFOhotel®, INFOrest®,
                  INFOback® o INFOfact®. Ofrecemos atención remota o presencial
                  24/7/365 exclusivamente para usuarios del
                  <Box component={"span"} sx={{ fontWeight: 500 }}>
                    {""} servicio SCO.
                  </Box>
                </Typography>
              </Box>
              {/* BOTON*/}
              <ButtonText
                height="56px"
                width="401px"
                onClick={() => {}}
                title="Reglamento del servicio de emergencia"
                active={true}
                bgColor={"#4884E2"}
                hoverColor={"#69C4DE"}
                fontSize={15}
              />
            </Box>
          </Box>
        </Box>
        {/*frame 1245 */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            alignContent: "center",
            gap: "20px",
            alignSelf: "stretch",
            flexWrap: "wrap",
          }}
        >
          {/* atencion*/}
          <Box
            sx={{
              display: "flex",
              width: "590px",
              height: "335px",
              padding: "40px",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "20px",
              borderRadius: "20px",
              border: "1px solid  #CADCF7",
              background: "#F3F8FD",
            }}
          >
            {/*Boton icono */}
            <Box
              sx={{
                display: "flex",
                width: "66px",
                height: "66px",
                padding: "16.5px",
                justifyContent: "center",
                alignItems: "center",
                flexShrink: 0,
                borderRadius: "24px",
                background: "#FFF",
                boxShadow: "0px 2px 10px 0px rgba(30, 41, 71, 0.14)",
              }}
            >
              <Box
                component={"img"}
                sx={{
                  width: "33px",
                  height: "33px",
                  display: "flex",
                  alignItems: "center",
                  WebkitMaskImage: `url(${themeMode.icons.usuario})`,
                  WebkitMaskSize: "33px",
                  WebkitMaskRepeat: "no-repeat",
                  WebkitMaskPosition: "center",
                  backgroundColor: "#3677DE",
                }}
              />
            </Box>
            {/*texto */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "20px",
                alignSelf: "stretch",
              }}
            >
              <Typography
                sx={{
                  alignSelf: "stretch",
                  fontFamily: "Montserrat",
                  fontSize: "24px",
                  fontWeight: 400,
                  lineHeight: "120%",
                }}
              >
                Atención al cliente
              </Typography>
              {/*info */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: "16px",
                }}
              >
                {/*numero */}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "20px",
                  }}
                >
                  <Box
                    component={"img"}
                    sx={{
                      width: "28px",
                      height: "28px",
                      display: "flex",
                      alignItems: "center",
                      WebkitMaskImage: `url(${themeMode.icons.mensaje})`,
                      WebkitMaskSize: "28px",
                      WebkitMaskRepeat: "no-repeat",
                      WebkitMaskPosition: "center",
                      backgroundColor: "#3677DE",
                    }}
                  />
                  <Typography
                    sx={{
                      fontFamily: "Montserrat",
                      fontSize: "20px",
                      fontWeight: 400,
                      lineHeight: "120%",
                    }}
                  >
                    +01 205 7550
                  </Typography>
                </Box>
                {/*pill */}
                <PillTexto
                  bgColor={"#EDF4FD"}
                  textColor={"#2664C7"}
                  TextName={"Lunes a viernes de 8:00 am - 6:00 pm"}
                  height={"32px"}
                  fontSize={"16px"}
                  padding={"10px 16px"}
                  fontFamily={"Poppins"}
                  fontWeight={500}
                />
              </Box>
            </Box>
          </Box>

          {/* Emergencia*/}
          <Box
            sx={{
              display: "flex",
              width: "590px",
              height: "335px",
              padding: "40px",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "20px",
              borderRadius: "20px",
              border: "1px solid  #FFD6D3",
              background: "#FFF6F5",
            }}
          >
            {/*Boton icono */}
            <Box
              sx={{
                display: "flex",
                width: "66px",
                height: "66px",
                padding: "16.5px",
                justifyContent: "center",
                alignItems: "center",
                flexShrink: 0,
                borderRadius: "24px",
                background: "#FFF",
                boxShadow: "0px 2px 10px 0px rgba(30, 41, 71, 0.14)",
              }}
            >
              <Box
                component={"img"}
                sx={{
                  width: "33px",
                  height: "33px",
                  display: "flex",
                  alignItems: "center",
                  WebkitMaskImage: `url(${themeMode.icons.alerta})`,
                  WebkitMaskSize: "33px",
                  WebkitMaskRepeat: "no-repeat",
                  WebkitMaskPosition: "center",
                  backgroundColor: "#FF655A",
                }}
              />
            </Box>
            {/*texto */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "20px",
                alignSelf: "stretch",
              }}
            >
              <Typography
                sx={{
                  alignSelf: "stretch",
                  fontFamily: "Montserrat",
                  fontSize: "24px",
                  fontWeight: 400,
                  lineHeight: "120%",
                }}
              >
                Emergencias
              </Typography>
              {/*info */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: "16px",
                }}
              >
                {/*numero 1*/}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "20px",
                  }}
                >
                  <Box
                    component={"img"}
                    sx={{
                      width: "28px",
                      height: "28px",
                      display: "flex",
                      alignItems: "center",
                      WebkitMaskImage: `url(${themeMode.icons.comercio})`,
                      WebkitMaskSize: "28px",
                      WebkitMaskRepeat: "no-repeat",
                      WebkitMaskPosition: "center",
                      backgroundColor: "#FF655A",
                    }}
                  />
                  <Typography
                    sx={{
                      fontFamily: "Montserrat",
                      fontSize: "20px",
                      fontWeight: 400,
                      lineHeight: "120%",
                    }}
                  >
                    Software: 989 200 773
                  </Typography>
                </Box>

                {/*numero 2*/}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "20px",
                  }}
                >
                  <Box
                    component={"img"}
                    sx={{
                      width: "28px",
                      height: "28px",
                      display: "flex",
                      alignItems: "center",
                      WebkitMaskImage: `url(${themeMode.icons.configuracion})`,
                      WebkitMaskSize: "28px",
                      WebkitMaskRepeat: "no-repeat",
                      WebkitMaskPosition: "center",
                      backgroundColor: "#FF655A",
                    }}
                  />
                  <Typography
                    sx={{
                      fontFamily: "Montserrat",
                      fontSize: "20px",
                      fontWeight: 400,
                      lineHeight: "120%",
                    }}
                  >
                    Servicio técnico: 989 200 764
                  </Typography>
                </Box>
                {/*pill */}
                <PillTexto
                  bgColor={"#FFECEB"}
                  textColor={"#FF655A"}
                  TextName={"Lunes a viernes las 24 horas del día"}
                  height={"32px"}
                  fontSize={"16px"}
                  padding={"10px 16px"}
                  fontFamily={"Poppins"}
                  fontWeight={500}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        {/*tickets */}
        <Box
          sx={{
            display: "flex",
            padding: "80px",
            flexDirection: "column",
            alignItems: "center",
            gap: "40px",
            alignSelf: "stretch",
            borderRadius: "20px",
            background: "#D9E6FA",
          }}
        >
          {/*titulo */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "28px",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Montserrat",
                fontSize: "36px",
                fontWeight: 500,
                lineHeight: "120%",
              }}
            >
              Tickets de servicio
            </Typography>
            {/* Separador */}
            <Box component={"img"} src={themeMode.icons.separador} />
          </Box>
          {/*contenido */}
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              alignContent: "flex-start",
              gap: "20px",
              alignSelf: "stretch",
              flexWrap: "wrap",
            }}
          >
            {/*desk */}
            <Box
              sx={{
                display: "flex",
                minWidth: "800px",
                padding: "40px",
                alignItems: "center",
                gap: "40px",
                flex: "1 0 0",
                borderRadius: "20px",
                background: "#FFF",
              }}
            >
              {/*LOGO */}
              <Box
                component={"img"}
                src={themeMode.icons.logoZohoDesk}
                sx={{
                  width: "180px",
                  height: "79px",
                }}
              />
              {/*texto */}
              <Typography
                sx={{
                  flex: "1 0 0",
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: 300,
                  lineHeight: "150%",
                }}
              >
                Utilizamos la plataforma Zoho Desk para gestionar el servicio al
                cliente, separando los
                <Box component={"span"} sx={{ fontWeight: 500 }}>
                  {""} incidentes o solicitudes,
                </Box>
                {""} aplicando acuerdos de servicio para mejorar el soporte y la
                continuidad operacional.
              </Typography>
            </Box>
            {/*incidentes */}
            <Box
              sx={{
                display: "flex",
                minWidth: "400px",
                padding: "40px",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "20px",
                flex: "1 0 0",
                borderRadius: "20px",
                background: "#FFF",
              }}
            >
              <Typography
                sx={{
                  alignSelf: "stretch",
                  fontFamily: "Montserrat",
                  fontSize: "24px",
                  fontWeight: 400,
                  lineHeight: "120%",
                }}
              >
                ¿Qué es un incidente?
              </Typography>
              <Typography
                sx={{
                  alignSelf: "stretch",
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: 300,
                  lineHeight: "150%",
                }}
              >
                Cualquier suceso que interrumpa o reduzca la calidad y
                productividad del usuario en nuestros servicios INFOhotel®,
                INFOrest®, INFOback® e INFOfact®.
              </Typography>
            </Box>
            {/*solicitud */}
            <Box
              sx={{
                display: "flex",
                minWidth: "400px",
                padding: "40px",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "20px",
                flex: "1 0 0",
                borderRadius: "20px",
                background: "#FFF",
              }}
            >
              <Typography
                sx={{
                  alignSelf: "stretch",
                  fontFamily: "Montserrat",
                  fontSize: "24px",
                  fontWeight: 400,
                  lineHeight: "120%",
                }}
              >
                ¿Qué es una solicitud?
              </Typography>
              <Typography
                sx={{
                  alignSelf: "stretch",
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: 300,
                  lineHeight: "150%",
                }}
              >
                Una petición de servicios o productos adicionales: licencias
                extras, garantías, capacitaciones, reparaciones, nuevos equipos,
                entre otros.
              </Typography>
            </Box>
          </Box>
          {/*boton texto */}
          <ButtonText
            height="56px"
            width="320px"
            onClick={() => {}}
            title="¡Ingresa a nuestro portal web!"
            active={true}
            bgColor={"#4884E2"}
            hoverColor={"#69C4DE"}
            fontSize={15}
          />
        </Box>
      </Box>
      {/*soluciones */}
      <SolucionesEquipo />
    </Box>
  );
};

export default Soporte;
