import { Box } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { themes } from "../../config/Icons";
import NavBarItem from "./NavBarItem";
import { useNavigate } from "react-router-dom";

interface Props {
  isDarkMode?: Boolean;
}

const NavBar: FC<Props> = ({ isDarkMode }) => {
  const themeMode = isDarkMode ? themes.dark : themes.light;
  const [isHovered, setIsHovered] = useState(false);
  const [showSecondaryBar, setShowSecondaryBar] = useState(false);
  const navigate = useNavigate();

  const menuItems = [
    { text: "NOSOTROS", hasDropdown: false, link: "nosotros" },
    {
      text: "SOLUCIONES",
      hasDropdown: true,
      link: "#",
      opciones: [
        { label: "INFOrest", link: "inforest" },
        { label: "INFOhotel", link: "infohotel" },
        { label: "INFOback", link: "infoback" },
        { label: "INFOfact", link: "infofact" },
        { label: "Equipos AMC", link: "productos" },
      ],
    },
    { text: "SOPORTE", hasDropdown: false, link: "soporte" },
    { text: "BLOG", hasDropdown: false, link: "#" },
  ];

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 20) {
        setShowSecondaryBar(true);
      } else {
        setShowSecondaryBar(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {/* Barra principal */}
      <Box
        sx={{
          display: "flex",
          width: {
            xs: "360px",
            sm: "640px",
            md: "640px",
            lg: "1280px",
            xl: "1280px",
          },
          height: "80px",
          padding: "0px 20px",
          justifyContent: "space-between",
          alignItems: "center",
          position: "fixed",
          top: 0,
          margin: "0 auto", // Centra la barra horizontalmente
          left: 0,
          right: 0,
          zIndex: 10,
          transition: "opacity 0.2s ease, transform 0.2s ease",
          opacity: showSecondaryBar ? 0 : 1, // Oculta la barra principal
        }}
      >
        {/* LOGO INFOMATICA */}
        <Box
          onClick={() => {
            navigate("/");
          }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          sx={{
            cursor:"pointer"
         }}
        >
          <Box
            component={"img"}
            src={
              isHovered
                ? themeMode.logo.logoInfomaticaColorHover
                : themeMode.logo.logoInfomaticaColorActivo
            }
            sx={{
              width: {
                xs: "120px",
                sm: "120px",
                md: "120px",
                lg: "180px",
                xl: "180px",
              },
              height: {
                xs: "42px",
                sm: "42px",
                md: "42px",
                lg: "63px",
                xl: "63px",
              },
              flexShrink: 0,
            }}
          />
        </Box>

        {/* Menú */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "24px",
          }}
        >
          {menuItems.map((item, index) => (
            <NavBarItem
              key={index}
              text={item.text}
              link={item.link}
              hasDropdown={item.hasDropdown}
              icon={item.hasDropdown ? themeMode.icons.flechaAbajo : null}
              options={item.opciones}
              colorText={showSecondaryBar ? "#2664C7" : "#2664C7"}
            />
          ))}
        </Box>
      </Box>

      {/* Barra secundaria que aparece al hacer scroll */}
      {showSecondaryBar && (
        <Box
          sx={{
            display: "flex",
            width: {
              xs: "360px",
              sm: "640px",
              md: "640px",
              lg: "1280px",
              xl: "1280px",
            },
            height: "64px",
            padding: "0px 20px",
            justifyContent: "space-between",
            alignItems: "center",
            position: "fixed",
            top: 0,
            margin: "0 auto",
            left: 0,
            right: 0,
            zIndex: 999,
            transition: "opacity 0.3s ease, transform 0.3s ease",
            background: "rgba(255, 255, 255, 0.95)",
            boxShadow: "0px 4px 20px 0px rgba(30, 41, 71, 0.12)",
            borderRadius: "0px 0px 16px 16px",
          }}
        >
          {/* LOGO INFOMATICA */}
          <Box
            onClick={() => {
              navigate("/");
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            sx={{
               cursor:"pointer"
            }}
          >
            <Box
              component={"img"}
              src={
                isHovered
                  ? themeMode.logo.logoInfomaticaColorHover
                  : themeMode.logo.logoInfomaticaColorActivo
              }
              sx={{
                width: "120px",
                height: "42px",
                flexShrink: 0,
              }}
            />
          </Box>

          {/* Menú */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "24px",
            }}
          >
            {menuItems.map((item, index) => (
              <NavBarItem
                key={index}
                text={item.text}
                link={item.link}
                hasDropdown={item.hasDropdown}
                icon={item.hasDropdown ? themeMode.icons.flechaAbajo : null}
                options={item.opciones}
                colorText={showSecondaryBar ? "#1E2947" : ""}
              />
            ))}
          </Box>
        </Box>
      )}
    </>
  );
};

export default NavBar;
