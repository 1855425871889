import { FC } from "react";
import { Box, Typography } from "@mui/material";
import { themes } from "../../config/Icons";

interface Props {
  label?: string;
  checked: boolean;
  onChange?: any;
  isDarkMode: Boolean;
  isAllSelected?: boolean;
  width?: string;
  height?: string;
  isHover?: boolean;
  isHead?: boolean;
}

const Checkboxtem: FC<Props> = ({
  label,
  checked,
  onChange,
  isDarkMode,
  isAllSelected,
  width,
  isHover,
  isHead,
}) => {
  const themeMode = isDarkMode ? themes.dark : themes.light;

  return (
    <Box
      sx={{
        display: "flex",
        height: "44px",
        width: width ? width : "auto",
        padding: "8px 20px 8px 0px",
        alignItems: "center",
        alignSelf: "stretch",
        cursor: "pointer",
        ":hover": {
          background: isHover ? themeMode.colors.Claro : "none",
        },
        "&:hover .check::after": {
          content: '""',
          position: "absolute",
          top: "2px",
          left: "2px",
          width: "calc(100% - 4px)",
          height: "calc(100% - 4px)",
          borderRadius: "2px",
          background: checked ? "transparent" : themeMode.colors.Activo,
        },
      }}
      onClick={onChange} // Cambiado a usar onClick
    >
      <Box
        sx={{
          display: "flex",
          padding: "12px",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <Box
          className="check"
          sx={{
            position: "relative",
            display: "flex",
            width: "18px",
            height: "18px",
            padding: "4px",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
            borderRadius: "4px",
            border: `1.5px solid ${themeMode.colors.Activo}`,
            backgroundColor: checked ? themeMode.colors.Activo : "transparent", // Fondo si está checked
          }}
        >
          {isAllSelected ? (
            // ICONO MENOS
            <Box
              sx={{
                width: " 12px",
                height: " 2px",
                flexShrink: "0",
                borderRadius: "2px",
                background: themeMode.colors.Pasivo,
              }}
            />
          ) :checked ? (
            // ICONO CHECK
            <Box
              component={"img"}
              sx={{
                width: "12px",
                height: "8.87px",
                flexShrink: 0,
              }}
              src={themeMode.icons.checkbox}
              alt="checkbox"
            />
          ) : (
            <Box
              sx={{
                width: "12px",
                height: "2px",
                flexShrink: "0",
                borderRadius: "2px",
                background: themeMode.colors.Pasivo,
              }}
            />
          )}
        </Box>
      </Box>

      <Typography
        variant="body2"
        fontFamily={"Poppins"}
        fontWeight={300}
        lineHeight={"16px"}
      >
        {label}
      </Typography>
    </Box>
  );
};
export default Checkboxtem;
