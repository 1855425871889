import { Box, Typography } from "@mui/material";
import { FC, useState } from "react";
import { themes } from "../../config/Icons";
import ButtonCircular from "../Buttones/ButtonCircular";
import Carousel from "react-material-ui-carousel";

interface Props {
  isDarkMode?: Boolean;
}

const CarouselOpiniones: FC<Props> = ({ isDarkMode }) => {
  const themeMode = isDarkMode ? themes.dark : themes.light;

  const testimonios = [
    {
      testimonio:
        "Confiamos en INFOrest®. Tiene un sistema amigable tanto en el back como en el front office, es de fácil uso de nuestros colaboradores y administradores, es de mucha ayuda para la gestión y control del negocio.",
      persona: "Daniel Rocca",
      vocacion: "Director Gerente",
      empresa: "Cosme",
      logo: themeMode.imagen.cosme,
      BgLocal: themeMode.imagen.localCosme,
    },
    {
      testimonio:
        "Inforest es nuestra mejor herramienta de gestión, tanto el Front Office (Atención de nuestros clientes) como el Back (Permite un estricto control de nuestros costos e inventarios para cumplir con nuestro objetivo de mejorar continuamente la rentabilidad de nuestros negocios.)",
      persona: "Marion Quirós",
      vocacion: "Gerente de Administración y Finanzas",
      empresa: "Rafael",
      logo: themeMode.imagen.rafael,
      BgLocal: themeMode.imagen.localRafael,
    },
    {
      testimonio:
        "Desde nuestra apertura en el 2005, confiamos en INFOrest, han pasado ya 13 años de ininterrumpida labor y siempre ha estado a la altura de las exigencias de la información y control, tiene módulos muy potentes que nos hacen sentir cómodos y satisfechos.",
      persona: "Jose Carpena",
      vocacion: "Director Fundador",
      empresa: "La Mar",
      logo: themeMode.imagen.laMar,
      BgLocal: themeMode.imagen.localLaMar,
    },
    {
      testimonio:
        "Inforest es nuestra mejor herramienta de gestión, tanto con el Front Office para la atención de nuestros clientes como en el Back, que nos permite tener un estricto control de nuestros costos e inventarios para cumplir con nuestro objetivo de mejorar continuamente la rentabilidad de nuestros negocios.",
      persona: "Marion Quirós",
      vocacion: "Gerente de Administración y Finanzas",
      empresa: "El Mercado",
      logo: themeMode.imagen.elMercado,
      BgLocal: themeMode.imagen.localMercado,
    },
  ];

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevStep) => (prevStep + 1) % testimonios.length);
  };

  const handlePrev = () => {
    setActiveStep((prevStep) => (prevStep - 1 + testimonios.length) % testimonios.length);
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "24px",
        position: "relative",
      }}
    >
      <Typography
        sx={{
          fontFamily: "montserrat",
          fontSize: "24px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "120%",
        }}
      >
        Sus opiniones nos respaldan
      </Typography>
      {/*testimonio */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "1200px",
          height: "388px",
        }}
      >
        <Carousel
         index={activeStep} // Se cambia selectedItem por index
         interval={4000}
         navButtonsAlwaysVisible={false}
         indicators={false}
         onChange={(_, newIndex) => {
          if (newIndex !== undefined) {
            setActiveStep(newIndex);
          }
        }}

          sx={{
            display: "flex",
            flexDirection: "row",
            width: "1200px",
            height: "388px",
            position: "relative",
          }}
        >
          {testimonios.map((item, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "1200px",
                height: "388px",
                gap: "40px",
                position: "relative",
                zIndex: 10, // Asegura que esté al frente
              }}
            >
              {/* INFO CLIENTE */}
              <Box
                sx={{
                  width: "620px",
                  height: "360px",
                  flexShrink: 0,
                  position: "relative",
                }}
              >
                {/* TESTIMONIO */}
                <Box
                  sx={{
                    display: "flex",
                    width: "620px",
                    height: "280px",
                    padding: "36px 24px",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    borderRadius: "20px",
                    background: "#FFFF",
                  }}
                >
                  <Typography
                    sx={{
                      alignSelf: "stretch",
                      color: "#1E2947",
                      fontFamily: "poppins",
                      fontSize: "16px",
                      fontWeight: 300,
                      lineHeight: "150%",
                    }}
                  >
                    {item.testimonio}
                  </Typography>
                  {/* CLIENTE */}
                  <Box
                    sx={{
                      display: "flex",
                      width: "368px",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <Typography
                      sx={{
                        alignSelf: "stretch",
                        color: "#1E2947",
                        fontFamily: "montserrat",
                        fontSize: "24px",
                        fontWeight: 400,
                        lineHeight: "120%",
                      }}
                    >
                      {item.persona}
                    </Typography>
                    <Typography
                      sx={{
                        alignSelf: "stretch",
                        color: "#88919E",
                        fontFamily: "poppins",
                        fontSize: "18px",
                        fontWeight: 300,
                        lineHeight: "22px",
                      }}
                    >
                      {item.vocacion}
                    </Typography>
                    <Typography
                      sx={{
                        alignSelf: "stretch",
                        color: "#3677DE",
                        fontFamily: "poppins",
                        fontSize: "16px",
                        fontWeight: 500,
                        lineHeight: "150%",
                      }}
                    >
                      {item.empresa}
                    </Typography>
                  </Box>
                </Box>

                {/* LOGO DEL CLIENTE */}
                <Box
                  component={"img"}
                  src={item.logo}
                  sx={{
                    width: "180px",
                    height: "180px",
                    flexShrink: 0,
                    borderRadius: "20px",
                    position: "absolute",
                    left: "420px",
                    top: "170px",
                    boxShadow: "0px 4px 16px 0px rgba(30, 41, 71, 0.12)",
                  }}
                />
              </Box>

              {/* IMAGEN FONDO DEL LOCAL */}
              <Box
                sx={{
                  flexGrow: 1, // Asegura que esta sección ocupe el espacio restante.
                  display: "inline-flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}
              >
                <Box
                  component={"img"}
                  src={item.BgLocal}
                  sx={{
                    width: "100%",
                    //height: "350px",
                    borderRadius: "20px",
                    objectFit: "cover", // Asegura que la imagen no se deforme.
                  }}
                />
              </Box>
            </Box>
          ))}
        </Carousel>
        {/* Dots */}
        <Box
          sx={{
            display: "inline-flex",
            alignItems: "flex-start",
            gap: "12px",
            position: "absolute",
            left: "94%",
            top: "98%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "8px",
            }}
          >
            {testimonios.map((_, i) =>
              i === activeStep ? (
                // Activo
                <Box
                  sx={{
                    width: "24px",
                    height: "8px",
                    borderRadius: "4px",
                    backgroundColor: themeMode.colors.Activo,
                  }}
                  key={i}
                />
              ) : (
                // Inactivo
                <Box
                  sx={{
                    width: "8px",
                    height: "8px",
                    borderRadius: "4px",
                    backgroundColor: "#CFD3D8",
                  }}
                  key={i}
                />
              )
            )}
          </Box>
        </Box>

        {/*FLECHA */}
        <Box
          sx={{
            display: "inline-flex",
            alignItems: "flex-start",
            gap: "12px",
            position: "absolute",
            left: "1132px",
            top: "350px", // Centra verticalmente
            transform: "translateY(-50%)", // Ajusta el centro
            zIndex: 20,
          }}
        >
          <ButtonCircular
            onClick={handlePrev}
            hoverColor={"#3677DE"}
            bgColor={"#3677DE"}
            iconHcolor={"#FFFFFF"}
            iconNcolor={"#FFFFFF"}
            iconName={themeMode.icons.flechaIzquierda}
            width="44px"
            height="44px"
            ImageSize= "24px"
            visibility={{
              xs: "hidden", // móviles
              md: "visible", // tablet
              lg: "visible", // laptop
              xl: "visible", // PC
            }}
          />

          <ButtonCircular
            onClick={handleNext}
            hoverColor={"#3677DE"}
            bgColor={"#3677DE"}
            iconHcolor={"#FFFFFF"}
            iconNcolor={"#FFFFFF"}
            iconName={themeMode.icons.flechaDerecha}
            width="44px"
            height="44px"
            ImageSize= "24px"
            visibility={{
              xs: "hidden", // móviles
              md: "visible", // tablet
              lg: "visible", // laptop
              xl: "visible", // PC
            }}
          />
        </Box>
      </Box>

      {/*logo  */}
      <Box
        sx={{
          width: "120px",
          height: "104px",
          position: "absolute",
          right: "-40px",
          top: "24px",
          strokeWidth: "3px",
          stroke: "#3677DE",
          filter: "drop-shadow(0px 6px 16px rgba(30, 41, 71, 0.24))",
          zIndex: 20,
        }}
      >
        <Box
          component={"img"}
          src={themeMode.icons.comillas}
          sx={{
            width: "120px",
            height: "104px",
          }}
        />
      </Box>
    </Box>
  );
};

export default CarouselOpiniones;
