import { Box, SelectChangeEvent, Typography } from "@mui/material";
import { ChangeEvent, FC, FocusEvent, useState } from "react";
import { themes } from "../../../config/Icons";
import TextFieldText from "../../../components/Textfield/TextFieldText";
import ButtonText from "../../../components/Buttones/ButtonText";
import { PillTexto } from "../../../components/Pills/PillTexto";
import Checkboxtem from "../../../components/CheckBox/Checkboxtem";
import TextInputCustom from "../../../components/Textfield/TextInputCustom";
import SelectTextFile from "../../../components/SelectText/SelectTextFile";

interface Props {
  isDarkMode: any;
}
const Formulario: FC<Props> = ({ isDarkMode }) => {
  const themeMode = isDarkMode ? themes.dark : themes.light;
  const [selectedNegocio, setSelectedNegocio] = useState("");
  const [selectedNosotros, setSelectedNosotros] = useState("");

  const labels = [
    "INFOrest",
    "INFOhotel",
    "INFOback",
    "INFOfact",
    "AMC",
    "Autoservicio",
    "Equipos",
  ];

  const [checkedItems, setCheckedItems] = useState<boolean[]>(
    labels.map(() => false)
  );

  const handleCheckboxChange = (index: number) => {
    setCheckedItems((prev) =>
      prev.map((item, i) => (i === index ? !item : item))
    );
  };

  // Función para manejar el cambio de selección
  const handleChangeInputValuesNegocio = (event: SelectChangeEvent) => {
    const selected = event.target.value;
    setSelectedNegocio(selected);
  };

  const handleChangeInputValuesNosotros = (event: SelectChangeEvent) => {
    const selected = event.target.value;
    setSelectedNosotros(selected);
  };

  const optionsNegocio = [
    {
      value: "Hotel", // Use the id as the value
      label: "Hotel", // Use the name as the label
    },
    {
      value: "Cafetería", // Use the id as the value
      label: "Cafetería", // Use the name as the label
    },
    {
      value: "Pastelería", // Use the id as the value
      label: "Pastelería", // Use the name as the label
    },
    {
      value: "Panadería", // Use the id as the value
      label: "Panadería", // Use the name as the label
    },
    {
      value: "Catering", // Use the id as the value
      label: "Catering", // Use the name as the label
    },
    {
      value: "Restobar", // Use the id as the value
      label: "Restobar", // Use the name as the label
    },
    {
      value: "Minimarket", // Use the id as the value
      label: "Minimarket", // Use the name as the label
    },
    {
      value: "Otro", // Use the id as the value
      label: "Otro", // Use the name as the label
    },
  ];

  const optionsNosotros = [
    {
      value: "Facebook",
      label: "Facebook",
    },
    {
      value: "Google",
      label: "Google",
    },
    {
      value: "LinkedIn",
      label: "LinkedIn",
    },
    {
      value: "Instagram",
      label: "Instagram",
    },
    {
      value: "TV",
      label: "TV",
    },
    {
      value: "Radio",
      label: "Radio",
    },
    {
      value: "Recomendación",
      label: "Recomendación",
    },
  ];

  const [observacion, setObservacion] = useState("");
  const handleChangeObservacion = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    console.log("obs", e.target.value);
    setObservacion(e.target.value);
  };

  const [activeFocusUsuario, setActiveFocusUsuario] = useState(false);
  const [usuario, setUsuario] = useState("");
  const [msjErrorUsuario, setMsjErrorUsuario] = useState("");
  const [errorUsuario, setErrorUsuario] = useState(false);
  const handleFocusUsuario = (event: FocusEvent<HTMLElement>) => {
    setActiveFocusUsuario(true);
    setMsjErrorUsuario("");
  };

  const handleBlurUsuario = (event: FocusEvent<HTMLElement>) => {
    setErrorUsuario(false);
    setActiveFocusUsuario(false);
  };

  const handleChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === "nUsuario") {
      setErrorUsuario(false);
      setUsuario(value);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        padding: "80px 0px",
        flexDirection: "column",
        alignItems: "center",
        gap: "60px",
        alignSelf: "stretch",
        background: "#D9E6FA",
      }}
    >
      {/*titulo */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "28px",
        }}
      >
        {/*texto */}
        <Typography
          sx={{
            color: "#1E2947",
            fontFamily: "Montserrat",
            fontSize: "36px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "120%",
          }}
        >
          ¡Contáctanos para darte la mejor solución!
        </Typography>
        {/*separador */}
        <Box component={"img"} src={themeMode.icons.separador} />
      </Box>
      {/*formulario */}
      <Box
        sx={{
          display: "flex",
          width: "1200px",
          padding: "32px",
          flexDirection: "column",
          alignItems: "center",
          gap: "40px",
          borderRadius: "20px",
          background: "#FFF",
          boxShadow: "0px 4px 16px 0px rgba(30, 41, 71, 0.12)",
        }}
      >
        {/*info */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "40px",
            alignSelf: "stretch",
          }}
        >
          {/*campos */}
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              alignContent: "flex-start",
              gap: "12px",
              alignSelf: "stretch",
              flexWrap: "wrap",
            }}
          >
            {/*Nombre y apellido */}
            <Box
              sx={{
                display: "flex",
                minWidth: "200px",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "4px",
                flex: "1 0 0",
              }}
            >
              <TextFieldText
                key={0}
                label="Nombre y apellido"
                titleInput=""
                nameValue={"nUsuario"}
                type={""}
                activeFocus={activeFocusUsuario}
                value={usuario}
                img={themeMode.icons.usuario}
                mensajeError={msjErrorUsuario}
                error={errorUsuario}
                maxLength={"50"}
                handleFocusLogin={handleFocusUsuario}
                handleBlurLogin={handleBlurUsuario}
                handleChangeInputValues={handleChangeInput}
                bgColor={"#2A6EDB"}
                hoverColor={"#2A6EDB"}
                bghover={"#EEF5FC"}
              />
            </Box>
            {/*Nombre de tu negocio */}
            <Box
              sx={{
                display: "flex",
                minWidth: "200px",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "4px",
                flex: "1 0 0",
              }}
            >
              <TextFieldText
                key={1}
                label="Nombre de tu negocio"
                titleInput=""
                nameValue={"nUsuario"}
                type={""}
                activeFocus={activeFocusUsuario}
                value={usuario}
                img={themeMode.icons.tienda}
                mensajeError={msjErrorUsuario}
                error={errorUsuario}
                maxLength={"50"}
                handleFocusLogin={handleFocusUsuario}
                handleBlurLogin={handleBlurUsuario}
                handleChangeInputValues={handleChangeInput}
                bgColor={"#2A6EDB"}
                hoverColor={"#2A6EDB"}
                bghover={"#EEF5FC"}
              />
            </Box>
            {/*Tipo de negocio */}

            <SelectTextFile
              value={selectedNegocio} // Aquí pasamos el valor controlado
              nameValue={""}
              handleChangeInputValues={handleChangeInputValuesNegocio} // Lógica de acciones
              imgAbajo={themeMode.icons.flechaAbajo}
              imgArriba={themeMode.icons.flechaAbajo}
              error={false}
              TextName={"Tipo de negocio"}
              msjError={""}
              options={optionsNegocio}
              bgColor={"#88919E"}
              hoverColor={themeMode.colors.lineaModal}
              bghover={themeMode.colors.ClaroHover}
              width={"240px"}
            />

            {/* Correo electrónico*/}
            <Box
              sx={{
                display: "flex",
                minWidth: "500px",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "4px",
                flex: "1 0 0",
              }}
            >
              <TextFieldText
                key={0}
                label="Correo electrónico"
                titleInput=""
                nameValue={"nUsuario"}
                type={"email"}
                activeFocus={activeFocusUsuario}
                value={usuario}
                img={themeMode.icons.correo}
                mensajeError={msjErrorUsuario}
                error={errorUsuario}
                maxLength={"50"}
                handleFocusLogin={handleFocusUsuario}
                handleBlurLogin={handleBlurUsuario}
                handleChangeInputValues={handleChangeInput}
                bgColor={"#2A6EDB"}
                hoverColor={"#2A6EDB"}
                bghover={"#EEF5FC"}
              />
            </Box>
            {/* Teléfono*/}
            <Box
              sx={{
                display: "flex",
                width: "260px",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "4px",
              }}
            >
              <TextFieldText
                key={0}
                label="Teléfono"
                titleInput=""
                nameValue={"nUsuario"}
                type={""}
                activeFocus={activeFocusUsuario}
                value={usuario}
                mensajeError={msjErrorUsuario}
                error={errorUsuario}
                maxLength={"50"}
                handleFocusLogin={handleFocusUsuario}
                handleBlurLogin={handleBlurUsuario}
                handleChangeInputValues={handleChangeInput}
                bgColor={"#2A6EDB"}
                hoverColor={"#2A6EDB"}
                bghover={"#EEF5FC"}
              />
            </Box>
            {/*¿Cómo supiste de nosotros?*/}

            <SelectTextFile
              value={selectedNosotros} // Aquí pasamos el valor controlado
              nameValue={""}
              handleChangeInputValues={handleChangeInputValuesNosotros} // Lógica de acciones
              imgAbajo={themeMode.icons.flechaAbajo}
              imgArriba={themeMode.icons.flechaAbajo}
              error={false}
              TextName={"¿Cómo supiste de nosotros?"}
              msjError={""}
              options={optionsNosotros}
              bgColor={"#88919E"}
              hoverColor={themeMode.colors.lineaModal}
              bghover={themeMode.colors.ClaroHover}
              width={"300px"}
            />
          </Box>
          {/*mensaje */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "28px",
              alignSelf: "stretch",
            }}
          >
            {/* temas */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "12px",
              }}
            >
              {/*titulo */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "20px",
                }}
              >
                <Typography
                  sx={{
                    color: " #88919E",
                    fontFamily: "Poppins",
                    fontSize: "20px",
                    fontWeight: 500,
                    lineHeight: "140%",
                  }}
                >
                  ¿Qué temas te interesan?
                </Typography>
                {/*PILL */}
                <PillTexto
                  bgColor={"#EDF4FD"}
                  textColor={"#2664C7"}
                  TextName={"Opcional"}
                  height={"24px"}
                  fontSize={"13px"}
                  padding={"10px 12px"}
                  fontFamily={"Poppins"}
                  fontWeight={500}
                />
              </Box>
              {/*checks */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  gap: "20px",
                }}
              >
                {labels.map((label, index) => (
                  <Checkboxtem
                    key={index}
                    label={label}
                    isDarkMode={isDarkMode}
                    checked={checkedItems[index]}
                    onChange={() => handleCheckboxChange(index)}
                    isHover={false}
                  />
                ))}
              </Box>
            </Box>
            {/*campo observacion*/}

            <TextInputCustom
              key={0}
              label="Observación"
              titleInput="Cuéntanos en qué podemos ayudarte..."
              nameValue={""}
              required={true}
              value={observacion}
              maxLength={"90"}
              onChange={handleChangeObservacion}
              color={"#4884E2"}
              colorhover={"#EEF5FC"}
            />
          </Box>
        </Box>
        {/*boton texto */}
        <ButtonText
          height="56px"
          width="320px"
          onClick={() => {}}
          title="¡estemos en contacto!"
          active={true}
          bgColor={"#4884E2"}
          hoverColor={"#69C4DE"}
          fontSize={15}
        />
      </Box>
    </Box>
  );
};

export default Formulario;
