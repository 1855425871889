import { FC } from "react";
import { Box } from "@mui/material";

interface Props {
  bgColor: string;
  textColor: string;
  TextName: string;
  height: any;
  width?: any;
  fontSize: any;
  padding: any;
  isIcon?: any;
  iconRoute?: any;
  onClick?: () => void;
  cursor?:any;
  fontFamily:any;
  fontWeight:any;
}

export const PillTexto: FC<Props> = ({
  onClick,
  bgColor,
  textColor,
  TextName,
  height,
  fontSize,
  padding,
  width,
  isIcon,
  iconRoute,
  cursor,
  fontFamily,
  fontWeight 
}) => {
  return (
    <Box
      onClick={onClick}
      sx={{
        display: "flex",
        width: width,
        height: height,
        background: bgColor,
        borderRadius: "20px",
        color: textColor,
        textAlign: "center",
        fontFamily: fontFamily,
        fontSize: fontSize,
        fontStyle: "normal",
        fontWeight: fontWeight,
        lineHeight: "14px",
        alignItems: "center",
        justifyContent: "center",
        padding: padding,
        gap: "4px",
        textTransform: "capitalize",
        //cursor: "pointer",
        cursor:cursor,
      }}
    >
      {isIcon && (
        <Box
          component="img"
          src={iconRoute}
          sx={{ width: "18px", height: "18px", marginRight: "4px" }}
        />
      )}
      {TextName}
    </Box>
  );
};
