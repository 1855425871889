import React, { useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import ButtonFlecha from "../../../../components/Buttones/ButtonFlecha";
import { themes } from "../../../../config/Icons";

interface HoverCardProps {
  imageSrc: string;
  title: string;
  description: string;
  buttonText: string;
  onClick: () => void;
  buttonColor: string;
  iconName: string;
  isDarkMode: Boolean;
}

const HoverCard: React.FC<HoverCardProps> = ({
  imageSrc,
  title,
  description,
  buttonText,
  onClick,
  isDarkMode
}) => {
      const themeMode = isDarkMode ? themes.dark : themes.light;
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Box
      sx={{
        display: "flex",
        height: "388px",
        flexDirection: "column",
        alignItems: "center",
        flex: "1 0 0",
        borderRadius: "20px",
        boxShadow: "0px 4px 16px 0px rgba(30, 41, 71, 0.12)",
        overflow: "hidden",
        position: "relative",
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {/* Imagen */}
      <Box
        component="img"
        src={imageSrc}
        sx={{
          borderRadius: "20px 20px 0px 0px",
          width: "100%",
          height: "240px",
          objectFit: "cover",
        }}
      />

      {/* Fondo negro (aparece/desaparece en hover) */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "240px",
          backgroundColor: "rgba(35, 35, 35, 0.5)",
          display: isHovered ? "none" : "flex",
          justifyContent: "center",
          alignItems: "center",
          transition: "opacity 0.3s",
        }}
      >
        <Typography
          sx={{
            color: "#FFF",
            fontFamily: "Montserrat",
            fontSize: "24px",
            fontWeight: 500,
            textAlign: "center",
          }}
        >
          {title}
        </Typography>
      </Box>

      {/*info */}
      <Box
        sx={{
          display: "flex",
          padding: "12px 20px",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "flex-start",
          flex: "1 0 0",
          alignSelf: "stretch",
        }}
      >
        <Typography
          sx={{
            alignSelf: "stretch",
            color: "#717883",
            fontFamily: "Poppins",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 300,
            lineHeight: "150%",
          }}
        >
          {description}
        </Typography>

        {/* Botón con Flecha */}
        <ButtonFlecha
          onClick={onClick}
          textColor={themeMode.colors.Activo}
          textName={buttonText}
          iconName={themeMode.icons.flechaLinealDerecha}
          bgColor={themeMode.colors.Activo}
        />
      </Box>
    </Box>
  );
};

export default HoverCard;
