import {
  Box,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  InputBase,
  InputLabel,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { useState } from "react";

interface Props {
  value: string;
  nameValue: string;
  handleChangeInputValues: (event: any) => void;
  options: any[];
  error: boolean;
  imgAbajo: string;
  imgArriba: string;
  TextName: string;
  msjError: string;
  bgColor: string;
  hoverColor: any;
  bghover: any;
  width?: any;
}

const CustomIcon: React.FC<{
  open: boolean;
  imgAbajo?: string;
  imgArriba?: string;
  color: any;
  onClick: any;
}> = ({ open, imgAbajo, imgArriba, color, onClick }) => {
  return (
    <Box
      component={"img"}
      sx={{
        width: "24px",
        height: "24px",
        transition: "transform 0.3s ease",
        transform: open ? "rotate(180deg)" : "rotate(0deg)",
        WebkitMaskImage: `url(${open ? imgArriba : imgAbajo})`,
        WebkitMaskSize: "24px",
        WebkitMaskRepeat: "no-repeat",
        WebkitMaskPosition: "center",
        backgroundColor: open ? color : color,
        cursor:"pointer"
      }}
      onClick={onClick}
    />
  );
};

const SelectTextFile: React.FC<Props> = ({
  value,
  error = false,
  msjError,
  nameValue,
  handleChangeInputValues,
  imgAbajo,
  imgArriba,
  TextName,
  options,
  hoverColor,
  bghover,
  bgColor,
  width,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const theme = useTheme();

  const handleIconClick = () => {
    setOpen(!open); // Toggle the open state when the icon is clicked
  };

  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "& .MuiInputBase-input": {
      fontFamily: "Poppins",
      fontSize: "14px",
      fontWeight: 300,
      colors:"#88919E"
    },
  }));

  return (
    <Box
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      sx={{ width: width }}
    >
      <FormControl variant="outlined" fullWidth {...(error && { error: true })}>
        <InputLabel
          id="demo-simple-select-label"
          shrink={!!value} // Forzamos a que el label flote si hay un valor o está abierto
          sx={{
            fontFamily: "Poppins",
            fontSize: value ? "15px" : "14px", // Tamaño adecuado al estar enfocado o con valor
            fontWeight: value ? 500 : 300,
            marginTop: value ? "12px" : "0px",
            lineHeight: "18px",
            transition: "all 0.3s ease",
            color: value ? "#1E2947" : "#88919E", // Color del label cuando tiene foco o valor
            "&.Mui-focused": {
              color: value ? "#1E2947" : "#88919E", // Color al tener foco
            },
          }}
        >
          {TextName}
        </InputLabel>

        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          variant="outlined"
          name={nameValue}
          value={value}
          onChange={handleChangeInputValues}
          label={TextName}
          input={<BootstrapInput />}
          open={open}
          onOpen={handleOpen}
          onClose={handleClose}
          IconComponent={() => (
            <CustomIcon
              open={open}
              imgAbajo={imgAbajo}
              imgArriba={imgArriba}
              color={bgColor}
              onClick={handleIconClick} // Pass the onClick handler to the icon
            />
          )}
          MenuProps={{
            PaperProps: {
              style: {
                marginTop: theme.spacing(1),
                border: "1px solid  #4884E2",
              },
            },
          }}
          sx={{
            width: "100%",
            height: "56px",
            borderRadius: "4px",
            border:
              isHovered && !value
                ? `1px solid ${hoverColor}`
                : `1px solid #E2E5E8`,
            background: isHovered && !value ? bghover : "#FFF",
            paddingTop: "10px",
            paddingBottom: "10px",
            paddingRight: "12px",
            boxShadow:
              isHovered && !value
                ? "none"
                : "0px 4px 12px rgba(30, 41, 71, 0.08)",
            "& .MuiSelect-select": {
              fontFamily: "Poppins",
              fontSize: "14px",
              fontWeight: 300,
              paddingTop: "20px",
              paddingBottom: "20px",
              paddingLeft: "12px",
            },
            "&.Mui-focused": {
              border:
                isHovered && !value
                  ? `1px solid ${hoverColor}`
                  : `1px solid #E2E5E8`,
            },
          }}
        >
          {options.map((option, index) => (
            <MenuItem
              key={index}
              value={option.value}
              sx={{
                width: "100%",
                height: "44px",
                display: "flex",
                padding: "12px",
                alignItems: "center",
                gap: "20px",
                alignSelf: "stretch",
                fontFamily: "Poppins",
                fontSize: "14px",
                fontWeight: 300,
                lineHeight: "16px",
                ":hover": {
                  color: "#4884E2",
                },
              }}
            >
              {option.label}
            </MenuItem>
          ))}
        </Select>

        {error && (
          <FormHelperText style={{ color: "#FF655A" }}>
            {msjError}
          </FormHelperText>
        )}
      </FormControl>
    </Box>
  );
};

export default SelectTextFile;
