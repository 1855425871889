import { Box, Menu, MenuItem, Typography, useTheme } from "@mui/material";
import React, { FC, useState } from "react";
import { useNavigate } from "react-router-dom";

interface Props {
  text: string;
  hasDropdown: boolean;
  icon?: string | null;
  options?: { label: string; link: string }[];
  link: any;
  colorText: string;
}

const NavBarItem: FC<Props> = ({
  text,
  hasDropdown,
  icon,
  options,
  link,
  colorText,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const theme = useTheme();
  const navigate = useNavigate();
  //metodo menu
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    navigate(link);
  };

  return (
    <>
      <Box
        onClick={handleClick}
        sx={{
          display: "flex",
          alignItems: "center",
          gap: hasDropdown ? "8px" : "10px",
          alignSelf: "stretch",
          height: "44px",
          padding: "0px 12px",
          cursor: "pointer",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "-1px",
          }}
        >
          <Typography
            sx={{
              overflow: "hidden",
              color: colorText, // Aplica el color dinámico
              textOverflow: "ellipsis",
              fontFamily: "Poppins",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "16px",
              background:
                "linear-gradient(currentColor, currentColor) bottom / 0 .1em no-repeat",
              transition: "0.5s background-size",
              "&:hover": {
                backgroundSize: "100% .1em",
                backgroundPosition: "left bottom",
                color:"#3677DE" ,
              },
            }}
          >
            {text}
          </Typography>
        </Box>
        {/*Imagen flecha*/}
        {hasDropdown && icon && (
          <Box
            component="img"
            sx={{
              width: "24px",
              height: "24px",
              WebkitMaskImage: `url(${icon})`,
              WebkitMaskSize: "24px",
              WebkitMaskRepeat: "no-repeat",
              WebkitMaskPosition: "center",
              backgroundColor: "#2664C7",
              transform: open ? "rotate(180deg)" : "rotate(0deg)", // Rotación al abri
            }}
          />
        )}
      </Box>
      {hasDropdown && icon && (
        <Menu
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-top"
          anchorEl={anchorEl}
          open={open}
          onClose={() => {
            setAnchorEl(null);
          }}
          elevation={0}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          MenuListProps={{
            "aria-labelledby": "demo-customized-button",
            sx: {
              width: "200px",
              padding: 0,
            },
          }}
          slotProps={{
            paper: {
              style: {
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                borderRadius: "4px",
                border: "1px solid #E2E5E8",
                background: "#FFF",
                marginTop: theme.spacing(1),
                overflow: "hidden",
                padding: 0,
              },
            },
          }}
        >
          {options?.map((option, index) => (
            <MenuItem
              key={index}
              onClick={() => {
                navigate(option.link);
                setAnchorEl(null);
              }}
              sx={{
                width: "100%",
                height: "44px",
                display: "flex",
                alignItems: "center",
                gap: "20px",
                alignSelf: "stretch",
                fontFamily: "Poppins",
                fontSize: "14px",
                fontWeight: 300,
                lineHeight: "16px",
                ":hover": {
                  color: "#2A6EDB",
                  background: "#E7EFFC",
                },
                overflow: "hidden",
              }}
            >
              {option.label}
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  );
};

export default NavBarItem;
