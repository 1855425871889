import { Box, Typography } from "@mui/material";
import { FC, useState } from "react";
import { themes } from "../../config/Icons";
import { PillTexto } from "../../components/Pills/PillTexto";
import CardInfo from "../../components/Card/CardInfo";
import ButtonCircular from "../../components/Buttones/ButtonCircular";

interface Props {
  isDarkMode?: Boolean;
}

const Trayectoria: FC<Props> = ({ isDarkMode }) => {
  const themeMode = isDarkMode ? themes.dark : themes.light;

  const cardinfo = [
    {
      años: "1991",
      image: themeMode.imagen.turboSYS,
      description:
        "Se crea la empresa Turbosys S.R.L., desarrollando software de gestión de rentas municipales y facturación de servicios.",
    },
    {
      años: "1994",
      image: themeMode.imagen.tsHotel,
      description:
        "Creamos el primer software para hoteles desarrollado íntegramente en el Perú, “TS-Hotel”.",
    },
    {
      años: "2005",
      image: themeMode.imagen.turbosysSRL,
      description:
        "Turbosys S.R.L. es absorbida por Infhotel Servicios Informáticos S.A.C. TS-Hotel cambia de nombre a INFOhotel®.",
    },
    {
      años: "2006",
      image: themeMode.imagen.inforest,
      description:
        "INFOrest® llega junto a la demanda local de software para restaurantes tras el inicio del boom gastronómico.",
    },
    {
      años: "2008",
      image: themeMode.imagen.backOffice,
      description:
        "Se crea BackOffice® y los motores de transferencia contable, integrando módulos contables, controlando los stocks, mermas y food cost.",
    },
    {
      años: "2016",
      image: themeMode.imagen.infomatica,
      description:
        "Nace INFOMÁTICA, consolidando sus productos INFOhotel®, INFOrest® y AMC Technologies® y sus complementos INFOback®, INFOfact®.",
    },
    {
      años: "2018",
      image: themeMode.imagen.informate,
      description:
        "Nace INFORMATE®, nuestro primer centro autorizado de capacitaciones, con el objetivo de certificar en el uso de nuestros productos.",
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  // Función para mover el slider a la derecha
  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % cardinfo.length); // Desplaza y vuelve al inicio
  };

  // Función para mover el slider a la izquierda
  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + cardinfo.length) % cardinfo.length); // Desplaza y vuelve al final
  };

  return (
    <Box
      sx={{
        display: "flex",
        width: "1200px",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "60px",
      }}
    >
      {/*seccion */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start",
          gap: "24px",
        }}
      >
        <Typography
          sx={{
            textAlign: "center",
            fontFamily: "Montserrat",
            fontSize: "36px",
            fontWeight: 500,
            lineHeight: "120%",
          }}
        >
          Nuestra larga trayectoria
        </Typography>
        {/*separador */}
        <Box component={"img"} src={themeMode.icons.separador} />
      </Box>
      {/*line de tiempo */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "40px",
          alignSelf: "stretch",
          position: "relative",
        }}
      >

        {/* tiempo */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "12px",
            position: "relative",
            transform: `translateX(-${currentIndex * 100}px)`, // Ajustar distancia del slider
       
          }}
        >
          {/* LINEA */}
          <Box
            component={"img"}
            src={themeMode.icons.lineVertical}
            sx={{
              position: "absolute",
              marginLeft: "180px",
              zIndex: 0,
            }}
          />
          {cardinfo.map((card, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                width: "280px",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "10px",
                position: "relative",
                zIndex: 1,
              }}
            >
              {/* Primer PILL */}
              <PillTexto
                bgColor={"#3677DE"}
                textColor={"#FFF"}
                TextName={card.años}
                height={"40px"}
                fontSize={"24px"}
                padding={"10px 12px"}
                fontFamily={"Montserrat"}
                fontWeight={400}
              />
            </Box>
          ))}
        </Box>

        {/* info */}
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            gap: "12px",
            alignSelf: "stretch",
            transform: `translateX(-${currentIndex * 100}px)`, // Ajustar distancia del slider
          
          }}
        >
          {cardinfo.map((card, index) => (
            <CardInfo
              key={index}
              imageFondo={card.image}
              descripcion={card.description}
            />
          ))}
        </Box>

        {/* Botón izquierdo */}
        {currentIndex > 0 && (
          <Box
            sx={{
              position: "absolute",
              left: "40px",
              bottom: "176px",
            }}
          >
            <ButtonCircular
              width="48px"
              height="48px"
              onClick={handlePrev}
              bgColor={"rgba(255, 255, 255, 0.60)"}
              hoverColor={"#3677DE"}
              iconName={themeMode.icons.flechaIzquierda}
              iconHcolor={"rgba(255, 255, 255, 0.60)"}
              iconNcolor={"#3677DE"}
              ImageSize= "24px"
            />
          </Box>
        )}

        {/* Botón derecho */}
        {currentIndex < cardinfo.length - 1 && (
          <Box
            sx={{
              position: "absolute",
              right: "40px",
              bottom: "176px",
            }}
          >
            <ButtonCircular
              width="48px"
              height="48px"
              onClick={handleNext}
              bgColor={"rgba(255, 255, 255, 0.60)"}
              hoverColor={"#3677DE"}
              iconName={themeMode.icons.flechaDerecha}
              iconHcolor={"rgba(255, 255, 255, 0.60)"}
              iconNcolor={"#3677DE"}
              ImageSize= "24px"
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Trayectoria;
