import { useRef } from "react";
import Nosotros from "./Nosotros/Nosotros";
import Quienes from "./Quienes";
import Soluciones from "./Soluciones";
import Optimizamos from "./Optimizamos";
import Header from "../Header/Header";
import Cliente from "./Cliente";

const Body = () => {
  const solucionesRef = useRef<HTMLDivElement>(null);

  const scrollToSoluciones = () => {
    solucionesRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <Header onFindSolutionClick={scrollToSoluciones} />
      <Cliente />
      <Optimizamos />
      <Soluciones  ref={solucionesRef}/>
      <Quienes />
      <Nosotros isDarkMode={false} />
    </>
  );
};

export default Body;
