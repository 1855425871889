import { Box, Typography } from "@mui/material";
import React, { FC } from "react";
import { themes } from "../../config/Icons";

interface Props {
  isDarkMode?: Boolean;
}

const Principios: FC<Props> = ({ isDarkMode }) => {
  const themeMode = isDarkMode ? themes.dark : themes.light;

  const cardPrincipios = [
    {
      icon: themeMode.icons.GraficoLinea,
      title: "Misión",
      description:
        "Acompañar a cada cliente en la implantación de nuestras soluciones tecnológicas simplificando sus procesos de negocio.",
    },
    {
      icon: themeMode.icons.ojoMostrar,
      title: "Visión",
      description:
        "Ser una de las empresas líder en desarrollo de soluciones tecnológicas agregando valor a nuestros clientes, felicidad a nuestros colaboradoes y utilidades a nuestros accionistas.",
    },
  ];

  const cardValor = [
    {
      icon: themeMode.icons.FavoritoCheck,
      title: "Empatía",
    },
    {
      icon: themeMode.icons.ManoRespeto,
      title: "Respeto",
    },
    {
      icon: themeMode.icons.mensaje,
      title: "Honestidad",
    },
    {
      icon: themeMode.icons.BanderaCheck,
      title: "Perseverancia",
    },
    {
      icon: themeMode.icons.Estrella,
      title: "Lealtad",
    },
    {
      icon: themeMode.icons.UsuarioGrupo,
      title: "Confianza",
    },
    {
      icon: themeMode.icons.TiempoReloj,
      title: "Puntualidad",
    },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        width: "1200px",
        flexDirection: "column",
        alignItems: "center",
        gap: "60px",
      }}
    >
      {/*seccion */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "24px",
        }}
      >
        {/*titulo */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "24px",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Montserrat",
              fontSize: "36px",
              fontWeight: 500,
              lineHeight: "120%",
            }}
          >
            Nuestra larga trayectoria
          </Typography>
          {/* Separador */}
          <Box component={"img"} src={themeMode.icons.separador} />
        </Box>
      </Box>
      {/*grid */}
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          alignContent: "flex-start",
          alignSelf: "stretch",
          flexWrap: "wrap",
        }}
      >
        {/*Principios */}
        {cardPrincipios.map((card, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              height: "180px",
              minWidth: "500px",
              padding: "20px 40px",
              alignItems: "flex-start",
              gap: "28px",
              flex: "1 0 0",
            }}
          >
            {/*BOTON ICONO */}
            <Box
              sx={{
                display: "flex",
                width: "68px",
                height: "68px",
                padding: "17px",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "24px",
                background: "#FFF",
                boxShadow: "0px 2px 10px 0px rgba(30, 41, 71, 0.14)",
              }}
            >
              {/*logo*/}
              <Box
                component={"img"}
                src={card.icon}
                sx={{
                  width: "34PX",
                  height: "34px",
                }}
              />
            </Box>
            {/*texto */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "8px",
                flex: "1 0 0",
              }}
            >
              <Typography
                sx={{
                  alignSelf: "stretch",
                  fontFamily: "Montserrat",
                  fontSize: "24px",
                  fontWeight: 400,
                  lineHeight: "120%",
                }}
              >
                {card.title}
              </Typography>
              <Typography
                sx={{
                  alignSelf: "stretch",
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: 300,
                  lineHeight: "150%",
                }}
              >
                {card.description}
              </Typography>
            </Box>
          </Box>
        ))}
        {/*valores */}
        <Box
          sx={{
            display: "flex",
            minWidth: "500px",
            padding: "40px 0px",
            flexDirection: "column",
            alignItems: "center",
            gap: "28px",
            flex: "1 0 0",
          }}
        >
          <Typography
            sx={{
              textAlign: "center",
              fontFamily: "Montserrat",
              fontSize: "24px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "120%",
            }}
          >
            Valores
          </Typography>
          {/*INFO */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              alignContent: "center",
              gap: "23px 0px",
              alignSelf: "stretch",
              flexWrap: "wrap",
            }}
          >
            {cardValor.map((card, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  minWidth: "130px",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "12px",
                  flex: "1 0 0",
                }}
              >
                {/*boton icono */}
                <Box
                  sx={{
                    display: "flex",
                    width: "68px",
                    height: "68px",
                    padding: "17px",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "24px",
                    background: "#FFF",
                    boxShadow: "0px 2px 10px 0px rgba(30, 41, 71, 0.14)",
                  }}
                >
                  <Box
                    component="img"
                    sx={{
                      width: "34px",
                      height: "34px",
                      display: "flex",
                      alignItems: "center",
                      WebkitMaskImage: `url(${card.icon})`,
                      WebkitMaskSize: "34px",
                      WebkitMaskRepeat: "no-repeat",
                      WebkitMaskPosition: "center",
                      backgroundColor: "#3677DE",
                    }}
                  />
                </Box>

                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "150%",
                  }}
                >
                  {card.title}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Principios;
