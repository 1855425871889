import { Box } from "@mui/material";
// import Lottie from "lottie-react";
// import { useLotties } from "../../config/lotties";

interface Props {
  isDarkMode?: any;
}

const LoaderScreen: React.FC<Props> = ({ isDarkMode }) => {
 // const { LottieCarga } = useLotties({ isDarkMode });

  return (
    <Box
      sx={{
        position: "fixed",
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
        transform: "translate(0%, 0%)",
        backdropFilter: "blur(5px)",
        backgroundColor: "rgba(255, 255, 255, 0.10)",
        zIndex: 9999, // Asegúrate de que esté en el frente
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        opacity: 0.9, // Aplica la opacidad al contenedor completo
      }}
    >
      {/* <Lottie
        animationData={LottieCarga}
        loop={true}
        autoplay={true}
        style={{
          width: "280px",
          height: "280px",
        }}
      /> */}
    </Box>
  );
};

export default LoaderScreen;
