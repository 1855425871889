import IconButton from "@mui/material/IconButton";
import { Typography, Box, TextField } from "@mui/material";
import ErrorIcon from "../../assets/Icons/FORMULARIO/ALERTA_24X24.svg";
import { FC, useState } from "react";

interface Props {
  label: string;
  titleInput: string;
  valueInput?: string;
  nameValue?: string;
  handleChangeInputValues: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleClickShowLogin?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleMouseDownLogin?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleFocusLogin: (event: React.FocusEvent<HTMLElement>) => void;
  handleBlurLogin: (event: React.FocusEvent<HTMLElement>) => void;
  showLogin?: boolean;
  error?: boolean;
  type: string;
  maxLength?: string;
  minLength?: string;
  value: any;
  activeFocus?: any;
  length?: number;
  activoOculto?: any;
  activoVisible?: any;
  img?: any;
  mensajeError?: any;
  bgColor?: string;
  hoverColor?: string;
  bghover?: any;
}

const TextFieldText: FC<Props> = ({
  label,
  titleInput,
  nameValue,
  handleChangeInputValues,
  error,
  type,
  img,
  maxLength,
  minLength,
  value,
  activeFocus,
  length,
  handleClickShowLogin,
  mensajeError,
  handleMouseDownLogin,
  showLogin,
  activoOculto,
  activoVisible,
  handleFocusLogin,
  handleBlurLogin,
  bgColor,
  hoverColor,
  bghover,
}: Props) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <Box
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      sx={{
        height: { xs: "48px", sm: "48px", md: "48px", lg: "56px", xl: "56px" },
        width: "100%",
      }}
    >
      <TextField
        variant="standard"
        label={label}
        placeholder={titleInput}
        type={type}
        name={nameValue}
        value={value}
        onChange={handleChangeInputValues}
        {...(error && { error: true, helperText: "" })}
        InputProps={{
          disableUnderline: true, // <== added this
          endAdornment:
            activeFocus == true ? (
              length! > 0 ? (
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowLogin}
                  onMouseDown={handleMouseDownLogin}
                  sx={{
                    padding: "7px",
                    marginTop: "-20px",
                    marginRight: "4px",
                    height: "100%",
                  }}
                >
                  {showLogin ? (
                    <Box
                      component={"img"}
                      sx={{
                        width: "24px",
                        height: "24px",
                        WebkitMaskImage: `url(${activoOculto})`,
                        WebkitMaskSize: "24px",
                        WebkitMaskRepeat: "no-repeat",
                        WebkitMaskPosition: "center",
                        backgroundColor: bgColor,
                      }}
                    />
                  ) : (
                    <Box
                      component={"img"}
                      sx={{
                        width: "24px",
                        height: "24px",
                        WebkitMaskImage: `url(${activoVisible})`,
                        WebkitMaskSize: "24px",
                        WebkitMaskRepeat: "no-repeat",
                        WebkitMaskPosition: "center",
                        backgroundColor: bgColor,
                      }}
                    />
                  )}
                </IconButton>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "-20px",
                    paddingTop: "0px",
                    paddingRight: "10px",
                    paddingLeft: "10px",
                  }}
                >
                  <Box
                    component={"img"}
                    sx={{
                      width: "24px",
                      height: "24px",
                      WebkitMaskImage: `url(${img})`,
                      WebkitMaskSize: "24px",
                      WebkitMaskRepeat: "no-repeat",
                      WebkitMaskPosition: "center",
                      backgroundColor: bgColor,
                    }}
                  />
                </Box>
              )
            ) : (
              <Box
                component={"img"}
                src={img}
                sx={{
                  marginTop: "-20px",
                  marginRight: "10px",
                  alignItems: "center",
                }}
              />
            ),
        }}
        inputProps={{
          maxLength: maxLength,
          minLength: minLength,
        }}
        onFocus={handleFocusLogin}
        onBlur={handleBlurLogin}
        sx={{
          width: "100%",
          paddingBottom: "0px",
          "&.MuiFormControl-root": {
            borderRadius: "4px",
            height: {
              xs: "48px",
              sm: "48px",
              md: "56px",
              lg: "56px",
              xl: "56px",
            },
            boxShadow: isHovered && !value  ? "none" : "0px 4px 12px rgba(30, 41, 71, 0.08)",
            backgroundColor: isHovered && !value ? bghover : "#FFF",
            border: isHovered && !value ? `1px solid ${hoverColor}` : `1px solid #E2E5E8`,
            ":active": {
              backgroundColor: "#FFF",
              border: `1px solid ${bgColor}`,
            },
          },
          "& .MuiFormLabel-root": {
            marginTop: "-4px",
            paddingTop: value !== "" ? "20px" : "0px",
            paddingLeft: "14px",
            fontFamily: "Poppins",
            fontWeight: value !== "" ? 500 : 300,
            fontSize: {
              xs: "14px",
              sm: "14px",
              md: "16px",
              lg: "16px",
              xl: "16px",
            },
            lineHeight: {
              xs: "20px",
              sm: "20px",
              md: "24px",
              lg: "24px",
              xl: "24px",
            },
            color: value !== "" ? "" : "#88919E",
            "&.Mui-focused": {
              fontSize: {
                xs: "14px",
                sm: "14px",
                md: "16px",
                lg: "16px",
                xl: "16px",
              },
              lineHeight: {
                xs: "14px",
                sm: "14px",
                md: "18px",
                lg: "18px",
                xl: "18px",
              },
              color: "var(--colorPrimary-Black)",
              fontFamily: "Poppins",
              fontWeight: 500,
              paddingLeft: "14px",
              paddingTop: value !== "" ? "23px" : "23px",
            },
          },
          "& .MuiInputBase-root": {
            height: "100%",
            fontSize: {
              xs: "12px",
              sm: "12px",
              md: "14px",
              lg: "14px",
              xl: "14px",
            },
            lineHeight: {
              xs: "14px",
              sm: "14px",
              md: "18px",
              lg: "18px",
              xl: "18px",
            },
            fontFamily: "Poppins",
            fontWeight: 300,
            marginTop: "0px",
            paddingTop: value !== "" ? "20px" : "20px",
            paddingLeft: "12px",
            "& ::placeholder": {
              fontFamily: "Poppins",
              fontWeight: 300,
              fontSize: {
                xs: "14px",
                sm: "14px",
                md: "16px",
                lg: "16px",
                xl: "16px",
              },
              lineHeight: {
                xs: "20px",
                sm: "20px",
                md: "24px",
                lg: "24px",
                xl: "24px",
              },
            },
          },
        }}
      />
      {/*texto error */}
      <Typography
        alignItems="center"
        sx={{
          color: "#FF655A",
          display: mensajeError !== "" ? "flex" : "none",
          fontSize: "12px",
          fontFamily: "Poppins",
          fontWeight: 300,
          lineHeight: {
            xs: "16px",
            sm: "16px",
            md: "18px",
            lg: "18px",
            xl: "18px",
          },
          marginTop: "5px",
        }}
      >
        {/*  Mensaje de error  */}
        {mensajeError !== "" ? (
          <Box
            component={"img"}
            sx={{
              width: "12px",
              height: "12px",
              WebkitMaskImage: `url(${ErrorIcon})`,
              WebkitMaskSize: "12px",
              WebkitMaskRepeat: "no-repeat",
              WebkitMaskPosition: "center",
              backgroundColor: "#FF655A",
              marginRight: "4px",
            }}
          />
        ) : (
          ""
        )}{" "}
        {mensajeError}
      </Typography>
    </Box>
  );
};

export default TextFieldText;
