import { useState } from "react";
import { Box, Typography, Grid, Stack } from "@mui/material";
import SimpleLineChart from "../../components/Shared/Intranet/LinearChart";
import PieActiveArc from "../../components/Shared/Intranet/PieChart";
import BoxCantidad from "../../components/Shared/Intranet/BoxCantidad";
import ComunicadoMovil from "../../assets/intranet/ANUNCIO_360X360.jpg";
import Comunicado from "../../assets/intranet/ANUNCIO_680X280.jpg";
import CollapsibleTable from "../../components/Shared/Intranet/TableTickets";
import HistorialPasivo from "../../assets/intranet/HISTORIAL-PASIVO_48X48.svg";
import HistorialHover from "../../assets/intranet/HISTORIAL-HOVER_48X48.svg";
import HistorialActivo from "../../assets/intranet/HISTORIAL-ACTIVO_48X48.svg";

const Inicio = () => {
  const uData = [4000, 3000, 2000, 2780, 1890, 2390];
  const pData = [2400, 1398, 9800, 3908, 4800, 3800];
  const xLabels = ["Page A", "Page B", "Page C", "Page D", "Page E", "Page F"];

  const [showHistorial, setShowHistorial] = useState(false);

  const valores = [
    { valor: 1235, label: "Boletas" },
    { valor: 652, label: "Facturas" },
    { valor: 652, label: "N. Credito" },
    { valor: 123, label: "clientes" },
  ];

  return (
    <Box sx={{}}>
      <Box
        sx={{
          paddingLeft: { xs: "20px", md: "88px" },
          paddingRight: "20px",
          marginBottom: "64px",
        }}
      >
        <Typography
          sx={{
            color: "#2A6EDB",
            fontFamily: "GothamLight",
            fontSize: "24px",
          }}
        >
          ¡Bienvenido,{" "}
          <Typography
            component={"span"}
            sx={{
              color: "#2A6EDB",
              fontFamily: "Gotham",
              fontSize: "24px",
            }}
          >
            Martín Fiestas!
          </Typography>
        </Typography>

        <Box
          sx={{
            display: "flex",
          }}
        >
          <Grid
            container
            sx={{
              backgroundColor: "",
              width: "100%",
              height: "max-content",
            }}
            rowGap={"12px"}
            // columnGap={'20px'}
          >
            <Grid
              item
              xs={12}
              lg={8}
              sx={{
                paddingRight: {
                  xs: "0px",
                  lg: "20px",
                },
              }}
            >
              <Box
                sx={{
                  // width: '100%',
                  height: { xs: "max-content", lg: "368px" },
                  minHeight: "368px",
                  backgroundColor: "white",
                  boxShadow: " 0px 4px 16px 0px rgba(25, 67, 127, 0.12)",
                  borderRadius: "10px",
                  paddingX: "28px",
                  paddingY: "32px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <Box>
                    <Typography
                      sx={{
                        color: "#2A6EDB",
                        fontSize: "14px",
                        fontFamily: "Poppins",
                      }}
                    >
                      Mis Finanzas
                    </Typography>
                    <Typography
                      sx={{
                        color: "#232323",
                        fontSize: "20px",
                        fontFamily: "PoppinsSemiBold",
                        letterSpacing: 1,
                      }}
                    >
                      s/ 9999.99
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      backgroundColor: "#4884E2",
                      borderRadius: "30px",
                      width: "168px",
                      height: "48px",
                      display: { xs: "none", md: "flex" },
                      justifyContent: "center",
                      alignItems: "center",
                      ":hover": {
                        backgroundColor: "#69C4DE",
                        cursor: "pointer",
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#FFF",
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        textTransform: "uppercase",
                      }}
                    >
                      Quiero Pagar
                    </Typography>
                  </Box>
                </Box>
                <SimpleLineChart />
                <Box
                  sx={{
                    backgroundColor: "#4884E2",
                    borderRadius: "30px",
                    width: "100%",
                    height: "48px",
                    display: { xs: "flex", md: "none" },
                    justifyContent: "center",
                    alignItems: "center",
                    ":hover": {
                      backgroundColor: "#69C4DE",
                      cursor: "pointer",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      color: "#FFF",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      textTransform: "uppercase",
                    }}
                  >
                    Quiero Pagar
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              lg={4}
              sx={{
                paddingRight: {
                  xs: "0px",
                  lg: "20px",
                },
              }}
            >
              <Box
                sx={{
                  // width: '100%',
                  height: { xs: "max-content", lg: "368px" },
                  backgroundColor: "white",
                  boxShadow: " 0px 4px 16px 0px rgba(25, 67, 127, 0.12)",
                  borderRadius: "10px",
                  paddingX: "28px",
                  paddingY: "32px",
                  // display: 'flex',
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      color: "#2A6EDB",
                      fontSize: "14px",
                      fontFamily: "Poppins",
                    }}
                  >
                    Mis Finanzas
                  </Typography>
                  <Typography
                    sx={{
                      color: "#232323",
                      fontSize: "20px",
                      fontFamily: "PoppinsSemiBold",
                      letterSpacing: 1,
                    }}
                  >
                    s/ 9999.99
                  </Typography>
                </Box>
                <PieActiveArc />
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              lg={6}
              sx={{
                paddingRight: {
                  xs: "0px",
                  lg: "20px",
                },
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: { xs: "508px", lg: "368px" },
                  backgroundColor: "",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    height: { xs: "90%", lg: "280px" },
                    boxShadow: " 0px 4px 16px 0px rgba(25, 67, 127, 0.12)",
                    borderRadius: "10px",
                    backgroundColor: "white",
                    display: "flex",
                    flexDirection: "column",
                    gap: "12px",
                    paddingX: "24px",
                    paddingY: "28px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "4px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#2A6EDB",
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        fontWeight: "500px",
                      }}
                    >
                      Comunicado
                    </Typography>

                    <Typography
                      sx={{
                        color: "#232323",
                        fontFamily: "PoppinsSemiBold",
                        fontSize: "20px",
                        fontWeight: "500px",
                      }}
                    >
                      Estimados Clientes
                    </Typography>
                  </Box>

                  <Typography
                    sx={{
                      color: "#232323",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: "500px",
                    }}
                  >
                    Máx. 500 caracteres. Lorem ipsum dolor amet, consectetuer
                    adipiscing elit. Aenean commodo ligula eget dolor. Aenean
                    massa. Cum sociis natoque penatibus et magnis di parturient
                    montes, nascetur ridiculus mus. Donec quam felis, ultricies
                    nec, pellentesque eu, pretium quis, sem. Nulla consequat
                    massa quis enim. Donec pede justo, fringilla vel, aliquet
                    nec, vulputate eget, arcu. In justo, rhoncus ut, imperdiet
                    a, venenatis vitae, justo. Nullam dictum felis eu pede
                    mollis pretium. Integer tincidunt.
                  </Typography>
                  <Typography
                    sx={{
                      color: "#4884E2",
                      fontFamily: "Poppins",
                      fontSize: "16px",
                      fontWeight: "500px",
                    }}
                  >
                    ¡Seguimos mejorando para darte lo mejor!
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              lg={6}
              sx={{
                paddingRight: {
                  xs: "0px",
                  lg: "20px",
                },
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: { xs: "508px", lg: "368px" },
                }}
              >
                <Box
                  sx={{
                    display: { xs: "block", sm: "none" },
                    width: "100%",
                    height: "90%",
                    boxShadow: " 0px 4px 16px 0px rgba(25, 67, 127, 0.12)",
                    borderRadius: "10px",
                  }}
                >
                  <img src={ComunicadoMovil} width={"100%"} height={"100%"} />
                </Box>
                <Box
                  sx={{
                    display: { xs: "none", sm: "block" },
                    width: "100%",
                    height: "280px",
                    boxShadow: " 0px 4px 16px 0px rgba(25, 67, 127, 0.12)",
                    borderRadius: "10px",
                  }}
                >
                  <img src={Comunicado} width={"100%"} height={"100%"} />
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Box
            sx={{
              display: { xs: "none", lg: "flex" },
              width: "100%",
              maxWidth: "372px",
              maxHeight: "664px",
              // height: '100%',
              backgroundColor: "white",
              boxShadow: " 0px 4px 16px 0px rgba(25, 67, 127, 0.12)",
              borderRadius: "10px",
              paddingX: "28px",
              paddingY: "32px",
              flexDirection: "column",
              gap: "12px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography
                  sx={{
                    color: "#2A6EDB",
                    fontFamily: "Poppins",
                    fontSize: "16px",
                  }}
                >
                  Mis Solicitudes
                </Typography>
                <Typography
                  sx={{
                    color: "#232323",
                    fontFamily: "Poppins",
                    fontSize: "24px",
                  }}
                >
                  Ultimos Tickets
                </Typography>
              </Box>

              <Box
                sx={{
                  width: "48px",
                  height: "48px",
                  borderRadius: "24px",
                  backgroundImage: `url(${
                    showHistorial ? HistorialActivo : HistorialPasivo
                  })`,
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  boxShadow: "0px 2px 8px 0px rgba(25, 67, 127, 0.14)",
                  ":hover": {
                    backgroundImage: `url(${
                      showHistorial ? HistorialActivo : HistorialHover
                    })`,
                    cursor: "pointer",
                  },
                }}
                onClick={() => setShowHistorial(!showHistorial)}
              ></Box>
            </Box>

            <CollapsibleTable />

            {!showHistorial && (
              <Box
                sx={{
                  backgroundColor: "#4884E2",
                  width: "100%",
                  height: "56px",
                  borderRadius: "30px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  ":hover": {
                    backgroundColor: "#30ADD2",
                    cursor: "pointer",
                  },
                }}
              >
                <Typography
                  sx={{
                    color: "#FFF",
                    fontSize: "15px",
                    fontFamily: "Poppins",
                  }}
                >
                  Abrir Chat
                </Typography>
              </Box>
            )}
          </Box>
        </Box>

        <Box
          sx={{
            boxShadow: "0px 4px 16px 0px rgba(25, 67, 127, 0.12)",
            borderRadius: "10px",
            width: "100%",
            height: { xs: "", lg: "246px" },
            backgroundColor: "white",
            paddingY: "32px",
            paddingX: "28px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "56px",
              marginBottom: "28px",
            }}
          >
            <Typography
              sx={{
                color: "#2A6EDB",
                fontFamily: "PoppinsSemiBold",
              }}
            >
              Registros Semanales
            </Typography>
            <Typography
              sx={{
                color: "#232323",
                fontFamily: "PoppinsSemiBold",
                fontSize: "24px",
              }}
            >
              +12456
            </Typography>
          </Box>

          <Stack
            direction="row"
            spacing={{ xs: 1, sm: 2, md: 4, lg: 7 }}
            sx={{
              display: { xs: "none", sm: "flex" },
            }}
          >
            {valores.map((item, index) => (
              <BoxCantidad key={index} valor={item.valor} label={item.label} />
            ))}
          </Stack>
          <Stack
            direction="column"
            spacing={{ xs: 1, sm: 2, md: 4, lg: 7 }}
            sx={{
              display: { xs: "block", sm: "none" },
            }}
          >
            {valores.map((item, index) => (
              <BoxCantidad key={index} valor={item.valor} label={item.label} />
            ))}
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

export default Inicio;
