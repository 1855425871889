import React, { useContext } from "react";
import { Outlet, Navigate } from "react-router-dom";

import { Box, Container } from "@mui/material";
//import HeaderIntranet from '../context/LayoutComponents/Header';


// import Sidebar2 from '../context/LayoutComponents/Sidebar2';

const IntranetLayout = () => {
 

  return (
    <>
      {auth !== "" ? (
        <Box
          sx={{
            backgroundColor: "",
            flex: 1,
            height: { xs: "max-content", md: "100%" },
            width: "100%",
            paddingTop: "96px",
            paddingLeft: "0px",
            paddingRight: "0px",
          }}
        >
          {/* <Sidebar /> */}
          {/* <Sidebar2 /> */}
          {/* <HeaderIntranet /> */}

          <Outlet />
        </Box>
      ) : (
        <Navigate to={"/"} />
      )}
    </>
  );
};

export default IntranetLayout;
