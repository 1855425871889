import { useState, useEffect, FC } from "react";
import { Box, Menu, Typography, useTheme } from "@mui/material";
import { themes } from "../../config/Icons";
import ButtonCircular from "../../components/Buttones/ButtonCircular";
import ButtonText from "../../components/Buttones/ButtonText";

interface Props {
  isDarkMode?: Boolean;
}

const Whatsapp: FC<Props> = ({ isDarkMode }) => {
  const themeMode = isDarkMode ? themes.dark : themes.light;

  const [scrolling, setScrolling] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const theme = useTheme();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    const handleScroll = () => {
      const show = window?.scrollY > 100;
      if (show) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };
    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Box
      sx={{
        width: "100px",
        height: "150px",
        position: "fixed",
        bottom: 60,
        right: { xs: 0, md: 80 },
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        zIndex: 9999,
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {scrolling && (
          <ButtonCircular
            onClick={() => {
              window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            }}
            hoverColor={"#79DD8B"}
            bgColor={""}
            iconHcolor={"#FFFFFF"}
            iconNcolor={"#42C67E"}
            border={"1px solid #42C67E"}
            iconName={themeMode.icons.flechaLinealArriba}
            width="48px"
            height="48px"
            ImageSize="24px"
          />
        )}
      </Box>
      <Menu
        elevation={0}
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-top"
        anchorEl={anchorEl}
        open={open}
        onClose={() => {
          setAnchorEl(null);
        }}
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
          sx: {
            padding: 0,
            width: "400px",
            height: "274px",
          },
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        slotProps={{
          paper: {
            style: {
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              alignSelf: "stretch",
              borderRadius: "30px",
              marginTop: theme.spacing(1),
              boxShadow: "0px 4px 16px 0px rgba(25, 67, 127, 0.12)",
            },
          },
        }}
      >
        {/* Cabecera verde */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: "#25D366", // Color verde
            height: "60px",
            padding: "0 16px",
          }}
        >
          {/* Imagen alineada a la izquierda */}
          <img
            src="https://via.placeholder.com/40" // URL de la imagen
            alt="Imagen"
            style={{ height: "30px", width: "30px" }}
          />

          {/* Botón alineado a la derecha */}
          <ButtonCircular
            onClick={() => {
              setAnchorEl(null);
            }}
            hoverColor={"#FFFFFF"}
            bgColor={"#FFFFFF"}
            iconHcolor={"#42C67E"}
            iconNcolor={"#42C67E"}
            iconName={themeMode.icons.cerrar}
            width="36px"
            height="36px"
            ImageSize="24px"
            visibility={{
              xs: "hidden", // móviles
              md: "visible", // tablet
              lg: "visible", // laptop
              xl: "visible", // PC
            }}
          />
        </Box>
        {/* Borde horizontal con texto y carrito */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "8px 16px",
            width: "100%",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: "16px",
              fontWeight: 300,
              lineHeight: "28px",
            }}
          >
            ¡Hola! Estamos aquí para ayudarte. Detállanos tu consulta...
          </Typography>
        </Box>
        {/* Botón en la parte inferior */}{" "}
        <ButtonText
          height="56px"
          width="160px"
          onClick={() => {
            window.location.href =
              " https://api.whatsapp.com/send/?phone=51989200759&text=%C2%A1Hola+Infom%C3%A1tica%21+%EF%BF%BD+Solicito+informaci%C3%B3n+sobre&type=phone_number&app_absent=0";
          }}
          title={"Abrir chat"}
          active={true}
          bgColor={"#42C67E"}
          hoverColor={"#79DD8B"}
          fontSize={15}
        />
      </Menu>

      <ButtonCircular
        onClick={handleClick} // Ahora correctamente ejecuta la función
        hoverColor={"#79DD8B"}
        bgColor={"#42C67E"}
        iconHcolor={"#FFFFFF"}
        iconNcolor={"#FFFFFF"}
        iconName={themeMode.icons.mensaje}
        width="64px"
        height="64px"
        ImageSize="32px"
      />
    </Box>
  );
};

export default Whatsapp;
