import * as React from 'react';
import { PieChart } from '@mui/x-charts/PieChart';
const data = [
    { id: 1, value: 48, label: 'Servicio A', color: '#2A6EDB' },
    { id: 2, value: 36, label: 'Servicio B', color: '#30ADD2' },
    { id: 3, value: 28, label: 'Servicio C', color: '#80CFBC' },
    { id: 4, value: 22, label: 'Servicio D', color: '#4884E2' },
    { id: 5, value: 20, label: 'Servicio E', color: '#69C4DE' },
    { id: 6, value: 16, label: 'Servicio E', color: '#99D9C9' },
    { id: 7, value: 10, label: 'Servicio E', color: '#B5CEF3' },
    { id: 8, value: 8, label: 'Servicio E', color: '#C3E7F2' },
    { id: 9, value: 6, label: 'Servicio E', color: '#C0E7DE' },
  ];

export default function PieActiveArc() {
    return (
        <PieChart
            sx={{
                backgroundColor: '',
                width: {xs:'100%', sm:'50%', md:'100%', lg:'90%', xl:'90%' },

            }}
            

            height={200}
            series={[
                {
                    data,
                    highlightScope: { faded: 'global', highlighted: 'item' },
                    faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                },
            ]}

        />
    );
}