import { Box, Typography } from "@mui/material";
import { FC } from "react";

interface Props {
  imageFondo: any;
  descripcion: string;
}

const CardInfo: FC<Props> = ({ imageFondo, descripcion }) => {
  return (
    <Box
      sx={{
        display: "flex",
        width: "280px",
        height: "400px",
        flexDirection: "column",
        justifyContent: "flex-end",
        alignItems: "flex-start",
        borderRadius: "12px",
        background: "#fff",
        boxShadow: "0px 4px 16px 0px rgba(30, 41, 71, 0.12)",
      }}
    >
      {/*imagen */}
      <Box
        component={"img"}
        src={imageFondo}
        sx={{
          width: "280px",
          height: "210px",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          borderBottom: "1px solid  #E2E5E8",
          borderRadius: "12px 12px 0px 0px",
        }}
      />
      {/*info */}
      <Box
        sx={{
          display: "flex",
          padding: "20px",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
          flex: "1 0 0",
          alignSelf: "stretch",
        }}
      >
        <Typography
          sx={{
            flex: "1 0 0",
            fontFamily: "Poppins",
            fontSize: "16px",
            fontWeight: 300,
            lineHeight: "150%",
          }}
        >
          {descripcion}
        </Typography>
      </Box>
    </Box>
  );
};

export default CardInfo;
