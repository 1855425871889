import { Box, Typography } from "@mui/material";
import { FC, useState } from "react";
import { themes } from "../../../config/Icons";
import Section from "./Section";
import ButtonCircular from "../../../components/Buttones/ButtonCircular";
import { useNavigate } from "react-router-dom";

interface Props {
  isDarkMode: any;
}
const Footer: FC<Props> = ({ isDarkMode }) => {
  const themeMode = isDarkMode ? themes.dark : themes.light;
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();
  const [sections] = useState([
    {
      title: "Explora",
      items: [
        "Nombre de entrada",
        "Nombre de entrada",
        "Nombre de entrada ",
        "Facturación electrónica",
        "Cursos y capacitaciones",
      ],
    },
    {
      title: "Sobre nosotros",
      items: [
        "Nuestra historia",
        "Nuestro equipo",
        "Nuestros proyectos",
        "Trabaja con nosotros",
      ],
    },
    {
      title: "Nuestros servicios",
      items: ["INFOrest", "INFOhotel", "INFOback ", "INFOfact", "AMC"],
    },
  ]);

  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        alignSelf: "stretch",
      }}
    >
      {/*Frame 885 */}
      <Box
        sx={{
          display: "flex",
          padding: {
            xs: "40px 20px",
            sm: "40px 24px",
            md: "40px 24px",
            lg: "40px 380px",
            xl: "40px 380px",
          },
          justifyContent: "center",
          alignItems: {
            xs: "center",
            sm: "flex-start",
            md: "flex-start",
            lg: "flex-start",
            xl: "flex-start",
          },
          gap: {
            xs: "16px",
            sm: "40px",
            md: "40px",
            lg: "140px",
            xl: "140px",
          },
          flex: "1 0 0",
          alignSelf: "stretch",
          background: "#2664C7",
          flexDirection: {
            xs: "column",
            sm: "revert",
            md: "revert",
            lg: "revert",
            xl: "revert",
          },
        }}
      >
        {/*links */}
        <Box
          sx={{
            display: "flex",
            width: {
              xs: "280px",
              sm: "none",
              md: "740px",
              lg: "740px",
              xl: "740px",
            },
            justifyContent: "center",
            alignItems: "flex-start",
            gap: {
              xs: "12px",
              sm: "12px",
              md: "12px",
              lg: "40px",
              xl: "40px",
            },
            flexDirection: {
              xs: "column",
              sm: "column",
              md: "revert",
              lg: "revert",
              xl: "revert",
            },
            flex: {
              xs: "normal",
              sm: "1 0 0",
              md: "normal",
              lg: "normal",
              xl: "normal",
            },
          }}
        >
          {sections.map((section, index) => (
            <Section
              index={index}
              key={index}
              title={section.title}
              items={section.items}
              isDarkMode={isDarkMode}
            />
          ))}
        </Box>
        {/*Frame 924 */}
        <Box
          sx={{
            display: "flex",
            height: "244px",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {/*Frame 884 */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "24px",
            }}
          >
            {/*Frame 882 */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "4px",
              }}
            >
              <Typography
                sx={{
                  alignSelf: "stretch",
                  color: "#FFF",
                  fontFamily: "Poppins",
                  fontSize: "20px",
                  fontWeight: 500,
                  lineHeight: "140%",
                }}
              >
                Entérate de todo
              </Typography>

              <Typography
                sx={{
                  alignSelf: "stretch",
                  color: "#FFF",
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: 300,
                  lineHeight: "16px",
                }}
              >
                ¡Síguenos y no te pierdas de nada!
              </Typography>
            </Box>
            {/*Botones */}
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                gap: "28px",
              }}
            >
              {/*facebok */}
              <ButtonCircular
                onClick={() =>
                  (window.location.href =
                    "https://www.facebook.com/InfomaticaPeru")
                }
                bgColor={"#FFFFFF"}
                hoverColor={"#30ADD2"}
                iconName={themeMode.icons.Facebook}
                iconHcolor={"#FFFFFF"}
                iconNcolor={"#3677DE"}
                ImageSize= "24px"
              />

              {/*intagram*/}
              <ButtonCircular
                onClick={() =>
                  (window.location.href =
                    "https://www.instagram.com/infomatica.peru")
                }
                bgColor={"#FFFFFF"}
                hoverColor={"#30ADD2"}
                iconName={themeMode.icons.Instagram}
                iconHcolor={"#FFFFFF"}
                iconNcolor={"#3677DE"}
                ImageSize= "24px"
              />
              {/*Linkedin */}
              <ButtonCircular
                onClick={() =>
                  (window.location.href =
                    "https://www.linkedin.com/company/infomatica")
                }
                bgColor={"#FFFFFF"}
                hoverColor={"#30ADD2"}
                iconName={themeMode.icons.Linkedin}
                iconHcolor={"#FFFFFF"}
                iconNcolor={"#3677DE"}
                ImageSize= "24px"
              />
              {/*TikTok */}
              <ButtonCircular
                onClick={() =>
                  (window.location.href =
                    "https://www.tiktok.com/@infomatica.peru?_t=8fej4SULhsE&_r=1")
                }
                bgColor={"#FFFFFF"}
                hoverColor={"#30ADD2"}
                iconName={themeMode.icons.TikTok}
                iconHcolor={"#FFFFFF"}
                iconNcolor={"#3677DE"}
                ImageSize= "24px"
              />
            </Box>
          </Box>
          {/*logos infomatica */}
          <Box
            onClick={() => { navigate("/");}}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <Box
              component={"img"}
              src={
                isHovered
                  ? themeMode.logo.logoInfomaticaHover
                  : themeMode.logo.logoInfomaticaActivo
              }
              sx={{
                width: "240px",
                height: "84px",
                flexShrink: 0,
              }}
            />
          </Box>
        </Box>
      </Box>
      {/*Frame 140 */}
      <Box
        sx={{
          width: "100%",
          padding: "15px  20px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "10px",
          background: "#232323",
        }}
      >
        <Typography
          color={"#FFFFFF"}
          fontFamily="Poppins"
          fontWeight={500}
          textAlign="left"
          sx={{
            fontSize: {
              xs: 12,
              sm: 12,
              md: 14,
              lg: 14,
              xl: 14,
            },
            lineHeight: {
              xs: "14px",
              sm: "14px",
              md: "16px",
              lg: "16px",
              xl: "16px",
            },
          }}
        >
          Copyright © 2023 Infomática. Todos los derechos reservados{" "}
          <Box
            component={"span"}
            onClick={() =>
              (window.location.href =
                "https://www.infomatica.pe/terminos-y-condiciones/")
            }
            color={"#FFFFFF"}
            fontFamily="Poppins"
            fontWeight={300}
            sx={{
              cursor: "pointer",
              fontSize: {
                xs: "12px",
                sm: "12px",
                md: "14px",
                lg: "14px",
                xl: "14px",
              },
              lineHeight: {
                xs: "14px",
                sm: "14px",
                md: "16px",
                lg: "16px",
                xl: "16px",
              },
            }}
          >
            | Términos y condiciones |
          </Box>
          <Box
            component={"span"}
            onClick={() =>
              (window.location.href =
                "https://www.infomatica.pe/politica-privacidad/")
            }
            color={"#FFFFFF"}
            fontFamily="Poppins"
            fontWeight={300}
            sx={{
              cursor: "pointer",
              fontSize: {
                xs: 12,
                sm: 12,
                md: 14,
                lg: 14,
                xl: 14,
              },
              lineHeight: {
                xs: "14px",
                sm: "14px",
                md: "16px",
                lg: "16px",
                xl: "16px",
              },
            }}
          >
            {" "}
            Política de privacidad
          </Box>
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
