import React, { FC } from 'react'
import NavBar from '../../components/Navigation/NavBar'
import Whatsapp from './Whatsapp'
import { Outlet } from 'react-router-dom'
import Formulario from './Body/Formulario'
import Footer from './Footer/Footer'

interface Props {
    isDarkMode?: Boolean;
  }

const Layout:FC<Props> = ({ isDarkMode }) => {
  return (
    <>
    <NavBar />
    <Whatsapp />
    <Outlet />
    <Formulario isDarkMode={isDarkMode} />
    <Footer isDarkMode={isDarkMode} />
  </>
  )
}

export default Layout