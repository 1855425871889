import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { forwardRef } from "react";
import ButtonText from "../../../components/Buttones/ButtonText";
import { themes } from "../../../config/Icons";
import CardSoluciones from "../../../components/Card/CardSoluciones";
import { useNavigate } from "react-router-dom";

interface Props {
  isDarkMode?: Boolean;
  ref: any;
}

const Soluciones = forwardRef<HTMLDivElement, Props>(({ isDarkMode }, ref) => {
  const themeMode = isDarkMode ? themes.dark : themes.light;
  const navigate = useNavigate();
  const theme = useTheme();

  const solucionesData = [
    {
      fondo: themeMode.imagen.solucionesINFOREST,
      logo: themeMode.logo.logoInfoRest,
      iconflecha: themeMode.icons.flechaLinealDerecha,
      title: "INFOrest",
      descripcion:
        "150 caracteres máx. Lorem ipsum dolore sit amet, consecte tu adicing elit.",
      onClick: () => navigate("inforest"),
    },
    {
      fondo: themeMode.imagen.solucionesINFOHOTEL,
      logo: themeMode.logo.logoInfoHotel,
      iconflecha: themeMode.icons.flechaLinealDerecha,
      title: "INFOhotel",
      descripcion:
        "150 caracteres máx. Lorem ipsum dolore sit amet, consecte tu adicing elit.",
      onClick: () => navigate("infohotel"),
    },
    {
      fondo: themeMode.imagen.solucionesINFOHOTEL,
      logo: themeMode.logo.logoInfoback,
      iconflecha: themeMode.icons.flechaLinealDerecha,
      title: "INFOback",
      descripcion:
        "150 caracteres máx. Lorem ipsum dolore sit amet, consecte tu adicing elit.",
      onClick: () => navigate("infoback"),
    },
    {
      fondo: themeMode.imagen.solucionesINFOHOTEL,
      logo: themeMode.logo.logoAmc,
      iconflecha: themeMode.icons.flechaLinealDerecha,
      title: "AMC",
      descripcion:
        "150 caracteres máx. Lorem ipsum dolore sit amet, consecte tu adicing elit.",
      onClick: () => navigate("productos"),
    },
    {
      fondo: themeMode.imagen.solucionesINFOHOTEL,
      logo: themeMode.logo.logoInfoFact,
      iconflecha: themeMode.icons.flechaLinealDerecha,
      title: "INFOfact",
      descripcion:
        "150 caracteres máx. Lorem ipsum dolore sit amet, consecte tu adicing elit.",
      onClick: () => navigate("infofact"),
    },
  ];

  return (
    <Box
      ref={ref} // Conecta el ref aquí
      sx={{
        display: "flex",
        padding: "80px 0px",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "60px",
        alignSelf: "stretch",
      }}
    >
      {/*SERVICIO */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "40px",
        }}
      >
        {/*titulo */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "24px",
          }}
        >
          {/*TEXTO */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                textAlign: "center",
                fontFamily: "Montserrat",
                fontSize: {
                  xs: "24px",
                  sm: "24px",
                  md: "24px",
                  lg: "36px",
                  xl: "36px",
                },
                fontWeight: 500,
                lineHeight: "120%",
              }}
            >
              Brindamos soluciones completas y eficientes
            </Typography>
            <Typography
              sx={{
                textAlign: "center",
                fontFamily: "poppins",
                fontSize: "20px",
                fontWeight: 300,
                lineHeight: "120%",
              }}
            >
              Encuentra los mejores servicios
            </Typography>
          </Box>
          {/*separador */}
          <Box component={"img"} src={themeMode.icons.separador} />
        </Box>
        {/*OPC */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "1200px",
          }}
        >
          <Grid container spacing={2}>
            {/* Primera fila - 2 elementos */}
            {solucionesData.slice(0, 2).map((solucion, index) => (
              <Grid
                item
                xs={12} // Ocupa toda la fila en móvil
                sm={6} // Ocupa la mitad del ancho en pantallas medianas
                md={6} // Cada tarjeta ocupa la mitad de la fila en escritorio
                key={index}
              >
                <CardSoluciones {...solucion} />
              </Grid>
            ))}

            {/* Segunda fila - 3 elementos */}
            {solucionesData.slice(2).map((solucion, index) => (
              <Grid
                item
                xs={12} // Ocupa toda la fila en móvil
                sm={4} // Ocupa un tercio del ancho en pantallas medianas
                md={4} // Ocupa un tercio del ancho en escritorio
                key={index + 2} // Asegura una clave única para los elementos
              >
                <CardSoluciones {...solucion} />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>

      {/*boton texto */}
      <ButtonText
        height="56px"
        width="420px"
        onClick={() => {}}
        title="¡descubre nuestros servicios!"
        active={true}
        bgColor={"#4884E2"}
        hoverColor={"#69C4DE"}
        fontSize={15}
      />
    </Box>
  );
});

export default Soluciones;
