import { Box, Typography } from "@mui/material";
import { FC, useState } from "react";
import { themes } from "../../../config/Icons";
import ButtonFlecha from "../../../components/Buttones/ButtonFlecha";
import ButtonCircular from "../../../components/Buttones/ButtonCircular";
import CarouselOpiniones from "../../../components/Carousel/CarouselOpiniones";

interface Props {
  isDarkMode?: Boolean;
}
const Quienes: FC<Props> = ({ isDarkMode }) => {
  const themeMode = isDarkMode ? themes.dark : themes.light;

  const testimonios = [{}];
  const [activeStep, setActiveStep] = useState(0);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        alignSelf: "stretch",
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "1360px",
          padding: "80px 0px",
          flexDirection: "column",
          alignItems: "center",
          gap: "60px",
          borderRadius: "20px",
          background: "#D9E6FA",
        }}
      >
        {/*titulo */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "24px",
          }}
        >
          <Typography
            sx={{
              color: "#1E2947",
              textAlign: "center",
              fontFamily: "Montserrat",
              fontSize: {
                xs: "24px",
                sm: "24px",
                md: "24px",
                lg: "36px",
                xl: "36px",
              },
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "120%",
            }}
          >
            Nuestra experiencia se refleja en su éxito
          </Typography>
          {/*separador */}
          <Box component={"img"} src={themeMode.icons.separador} />
        </Box>
        {/*cards*/}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "40px",
          }}
        >
          {/*banner */}
          <Box
            sx={{
              width: "100%",
              height: "388px",
              flexShrink: 0,
              position: "relative",
            }}
          >
            {/*BANNER */}
            <Box
              sx={{
                display: "inline-flex",
                flexDirection: "column",
                alignItems: "flex-end",
                gap: "20px",
              }}
            >
              <Box
                sx={{
                  backgroundImage: {
                    xs: `url(${themeMode.imagen.bannerSummunMovil})`,
                    sm: `url(${themeMode.imagen.bannerSummunTable})`,
                    md: `url(${themeMode.imagen.bannerSummunTable})`,
                    lg: `url(${themeMode.imagen.bannerSummunPC})`,
                    xl: `url(${themeMode.imagen.bannerSummunPC})`,
                  },
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  width: {
                    xs: "336px",
                    sm: "616px",
                    md: "616px",
                    lg: "1200px",
                    xl: "1200px",
                  },

                  height:"100%",
                  padding: {
                    xs: "24px 36px 248px 20px",
                    sm: "24px 336px 0px 20px",
                    md: "24px 336px 0px 20px",
                    lg: "40px 720px 24px 40px",
                  },
                  display: "flex",
                  alignItems: "center",
                  borderRadius: "20px",
                  boxShadow: "0px 4px 16px 0px rgba(30, 41, 71, 0.12)",
                }}
              >
                {/*INFO */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "26px",
                  }}
                >
                  {/*AREA DE TEXTO */}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "24px",
                    }}
                  >
                    <Typography
                      component={"p"}
                      sx={{
                        width: { sm: "260px", md: "440px" },
                        height: "auto",
                        color: "#1E2947",
                        fontSize: { sm: "14px", md: "16px" },
                        fontFamily: "poppins",
                        fontWeight: 300,
                        lineHeight: "150%",
                      }}
                    >
                      Felicitamos a nuestros clientes y agradecemos ser aliados
                      del
                      <Box component={"span"} sx={{ fontWeight: 500 }}>
                        {" "}
                        80%
                      </Box>{" "}
                      de los restaurantes destacados en el
                      <Box component={"span"} sx={{ fontWeight: 500 }}>
                        {" "}
                        Top 20
                      </Box>{" "}
                      de los premios:
                    </Typography>

                    {/*imagen */}
                    <Box
                      component={"img"}
                      src={themeMode.icons.summum2023}
                      sx={{
                        width: {
                          xs: "140px",
                          sm: "140px",
                          md: "140px",
                          lg: "200px",
                          xl: "200px",
                        },
                        height: {
                          xs: "88px",
                          sm: "88px",
                          md: "88px",
                          lg: "126px",
                          xl: "126px",
                        },
                      }}
                    />
                  </Box>
                  {/* Botón con Flecha */}
                  <ButtonFlecha
                    onClick={() => {}}
                    textColor={themeMode.colors.Activo}
                    textName={"QUIERO SABER MÁS"}
                    iconName={themeMode.icons.flechaLinealDerecha}
                    bgColor={themeMode.colors.Activo}
                  />
                </Box>
              </Box>

              {/* Dots */}
              <Box
                sx={{
                  display: "inline-flex",
                  alignItems: "flex-start",
                  gap: "12px",
                  position: "absolute",
                  left: "94%",
                  top: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  {testimonios.map((_, i) =>
                    i === activeStep ? (
                      // Activo
                      <Box
                        sx={{
                          width: "24px",
                          height: "8px",
                          borderRadius: "4px",
                          backgroundColor: themeMode.colors.Activo,
                        }}
                        key={i}
                      />
                    ) : (
                      // Inactivo
                      <Box
                        sx={{
                          width: "8px",
                          height: "8px",
                          borderRadius: "4px",
                          backgroundColor: "#CFD3D8",
                        }}
                        key={i}
                      />
                    )
                  )}
                </Box>
              </Box>
            </Box>
            {/*FLECHAS */}
            <Box
              sx={{
                display: "inline-flex",
                alignItems: "flex-start",
                gap: "12px",
                position: "absolute",
                left: "1132px",
                top: "272px", // Centra verticalmente
                transform: "translateY(-50%)", // Ajusta el centro
                zIndex: 20,
              }}
            >
              <ButtonCircular
                onClick={() => {}}
                hoverColor={"#3677DE"}
                bgColor={"#3677DE"}
                iconHcolor={"#FFFFFF"}
                iconNcolor={"#FFFFFF"}
                iconName={themeMode.icons.flechaIzquierda}
                width="44px"
                height="44px"
                ImageSize="24px"
                visibility={{
                  xs: "hidden", // móviles
                  md: "visible", // tablet
                  lg: "visible", // laptop
                  xl: "visible", // PC
                }}
              />
              <ButtonCircular
                onClick={() => {}}
                hoverColor={"#3677DE"}
                bgColor={"#3677DE"}
                iconHcolor={"#FFFFFF"}
                iconNcolor={"#FFFFFF"}
                iconName={themeMode.icons.flechaDerecha}
                width="44px"
                height="44px"
                ImageSize="24px"
                visibility={{
                  xs: "hidden", // móviles
                  md: "visible", // tablet
                  lg: "visible", // laptop
                  xl: "visible", // PC
                }}
              />
            </Box>
          </Box>
          {/*testimonial */}
           <CarouselOpiniones /> 
        </Box>
      </Box>
    </Box>
  );
};

export default Quienes;
