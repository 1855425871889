import { FC, useEffect, useState } from "react";
import { themes } from "../../../config/Icons";
import { Box, Typography } from "@mui/material";
import HeaderIcon from "./HeaderIcon";
import ButtonFlecha from "../../../components/Buttones/ButtonFlecha";
import ButtonText from "../../../components/Buttones/ButtonText";

interface Props {
  isDarkMode?: Boolean;
  onFindSolutionClick: () => void;
}

const Header: FC<Props> = ({ isDarkMode, onFindSolutionClick }) => {
  const themeMode = isDarkMode ? themes.dark : themes.light;
  const [activeStep, setActiveStep] = useState(0);
  const [activeText, setActiveText] = useState(0);
  const industrias = [
    "restaurantes",
    "hoteles",
    "cafeterias",
    "bares",
    "pastelerias",
    "panaderias",
  ];

  const services = [
    {
      iconUrl: themeMode.icons.restaurantes,
      title: "Restaurantes",
    },
    {
      iconUrl: themeMode.icons.hoteles,
      title: "Hoteles",
    },
    {
      iconUrl: themeMode.icons.cafeteria,
      title: "Cafeterías",
    },
    {
      iconUrl: themeMode.icons.bares,
      title: "Bares",
    },
    {
      iconUrl: themeMode.icons.pastelerias,
      title: "Pastelerías",
    },
  ];

  const images = [
    themeMode.imagen.hero1,
    themeMode.imagen.hero2,
    themeMode.imagen.hero3,
  ];

  const maxSteps = images.length;
  const maxText = industrias.length;

  // Cambia la imagen automáticamente cada 3 segundos
  useEffect(() => {
    const interval = setInterval(() => {
      setActiveStep((prevActiveStep) => (prevActiveStep + 1) % maxSteps);
      setActiveText((prevActiveStep) => (prevActiveStep + 1) % maxText);
    }, 3000);

    return () => clearInterval(interval);
  }, [maxSteps, maxText]);

  return (
    <Box
      sx={{
        backgroundImage: `url(${themeMode.imagen.fondo})`,
        display: "flex",
        height: {
          xs: "none",
          sm: "none",
          md: "none",
          lg: "960px",
          xl: "960px",
        },
        padding: {
          xs: "60px 12px 120px 12px",
          sm: "60px 12px 120px 12px",
          md: "60px 12px 120px 12px",
          lg: "none",
          xl: "none",
        },
        width: "100%",
        backgroundSize: "cover",
        backgroundPosition: "center",
        alignSelf: "stretch",
        alignItems: "center", // Centra verticalmente
        justifyContent: "center", // Centra horizontalmente
        flexDirection: {
          xs: "column",
          sm: "column",
          md: "column",
          lg: "revert",
          xl: "revert",
        },
        gap: {
          xs: "10px",
          sm: "10px",
          md: "10px",
          lg: "none",
          xl: "none",
        },
      }}
    >
      {/*hero */}
      <Box
        sx={{
          display: "flex",
          width: {
            xs: "336px",
            sm: "616px",
            md: "616px",
            lg: "1228px",
            xl: "1228px",
          },
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        {/*INFO CTA */}
        <Box
          sx={{
            display: "flex",
            padding: "60px 0px",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: {
              xs: "40px",
              sm: "40px",
              md: "40px",
              lg: "60px",
              xl: "60px",
            },
            width: "595px",
          }}
        >
          {/*info */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "20px",
              alignSelf: {
                xs: "stretch",
                sm: "stretch",
                md: "stretch",
                lg: "none",
                xl: "none",
              },
            }}
          >
            {/*h1 */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Typography
                sx={{
                  width: "100%",
                  maxWidth: "600px",
                  WebkitTextStrokeWidth: "1.5px",
                  WebkitTextStrokeColor: "#2664C7",
                  WebkitTextFillColor: "transparent",
                  fontFamily: "Montserrat",
                  fontSize: {
                    xs: "36px",
                    sm: "36px",
                    md: "36px",
                    lg: "63px",
                    xl: "63px",
                  },
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "112%",
                }}
              >
                Soluciones tecnológicas para
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  height: {
                    xs: "40px",
                    sm: "40px",
                    md: "40px",
                    lg: "72px",
                    xl: "72px",
                  },
                  alignItems: "center",
                  position: "relative",
                }}
              >
                {industrias.map((item, index) => (
                  <Typography
                    component="span"
                    key={index}
                    sx={{
                      color: "#2664C7",
                      fontFamily: "Montserrat",
                      fontWeight: 700,
                      lineHeight: "112%",
                      fontSize: {
                        xs: "36px",
                        sm: "36px",
                        md: "36px",
                        lg: "63px",
                        xl: "63px",
                      },
                      alignItems: "center",
                      position: "absolute",
                      opacity: activeText === index ? 1 : 0,
                      transition: "opacity 1s ease-in-out",
                      zIndex: activeText === index ? 1 : 0,
                    }}
                  >
                    {item}
                  </Typography>
                ))}
              </Box>
            </Box>
            {/*bajada */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "24px",
              }}
            >
              <Typography
                sx={{
                  color: "#1E2947",
                  fontFamily: "Poppins",
                  fontSize: {
                    xs: "16px",
                    sm: "16px",
                    md: "16px",
                    lg: "20px",
                    xl: "20px",
                  },
                  fontWeight: 300,
                  lineHeight: "140%",
                }}
              >
                Somos pioneros con 20 años de experiencia en el desarrollo e
                innovación de la industria HORECA.
              </Typography>
              {/*iconos bajada */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  gap: {
                    xs: "none",
                    sm: "none",
                    md: "none",
                    lg: "12px",
                    xl: "12px",
                  },
                }}
              >
                {services.map((service, index) => (
                  <HeaderIcon
                    key={index}
                    icon={service.iconUrl}
                    label={service.title}
                  />
                ))}
              </Box>
            </Box>
          </Box>
          {/*boton texto */}
          <ButtonText
            height={{
              xs: "48px",
              sm: "48px",
              md: "48px",
              lg: "56px",
              xl: "56px",
            }}
            width={{
              xs: "100%",
              sm: "320px",
              md: "320px",
              lg: "300px",
              xl: "300px",
            }}
            onClick={onFindSolutionClick}
            title="encuentra tu solución"
            active={true}
            bgColor={"#4884E2"}
            hoverColor={"#69C4DE"}
            fontSize={{
              xs: 14,
              sm: 14,
              md: 14,
              lg: 15,
              xl: 15,
            }}
          />
        </Box>
        {/*HEADER FONDO */}
        <Box
          sx={{
            width: "632px",
            height: "632px",
            display: {
              xs: "none",
              sm: "none",
              md: "none",
              lg: "flex",
              xl: "flex",
            },
            alignItems: "center",
          }}
        >
          {images.map((item, i) => (
            <Box
              key={i}
              component="img"
              src={item}
              sx={{
                width: "632px",
                height: "632px",
                position: "absolute",
                alignItems: "center",
                opacity: activeStep === i ? 1 : 0, // Solo la imagen activa es visible
                transition: "opacity 1s ease-in-out", // Transición suave de opacidad
                zIndex: activeStep === i ? 1 : 0, // Asegura que la imagen activa esté encima
              }}
            />
          ))}
        </Box>
      </Box>

      {/* Botón con Flecha */}
      <Box
        sx={{
          position: "absolute", // Posiciona el botón de manera absoluta respecto al contenedor principal
          bottom: "120px",
          right: "11%",
        }}
      >
        <ButtonFlecha
          onClick={() => {}}
          textColor={themeMode.colors.Activo}
          textName={"Más sobre nosotros"}
          iconName={themeMode.icons.flechaLinealDerecha}
          bgColor={themeMode.colors.Activo}
        />
      </Box>
    </Box>
  );
};

export default Header;
