import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { themes } from "../../../config/Icons";

interface Props {
  title: string;
  items: string[];
  isDarkMode: Boolean;
  index: number;
}

const Section: FC<Props> = ({ title, items, isDarkMode, index }) => {
  const themeMode = isDarkMode ? themes.dark : themes.light;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  // Controlar el índice del acordeón expandido
  const [expandedIndex, setExpandedIndex] = useState<number | null>(isMobile ? null : index);

  useEffect(() => {
    if (!isMobile) {
      setExpandedIndex(index); // En pantallas grandes, todos los acordeones se expanden
    } else {
      setExpandedIndex(null); // En pantallas móviles, ninguno está expandido por defecto
    }
  }, [isMobile, index]);

  // Función para manejar el cambio de estado de expansión
  const handleToggle = (index: number) => {
    // En móviles, asegurarse de que solo un acordeón se expanda a la vez
    setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <Box
      sx={{
        display: "flex",
        width: {
          xs: "100%",
          sm: "100%",
          md: "220px",
          lg: "220px",
          xl: "220px",
        },
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "12px",
        flexShrink: 0,
      }}
    >
      {/* Encabezado del acordeón */}
      <Box
        onClick={() => {
          if (isMobile) {
            handleToggle(index); // Solo en móviles, cambiar el estado de expansión.
          }
        }}
        sx={{
          display: "flex",
          height: "40px",
          alignItems: "center",
          gap: "20px",
          alignSelf: "stretch",
          cursor: isMobile ? "pointer" : "default",
          borderBottom:
            isMobile && expandedIndex === index ? "1px solid #FFF" : "0px", // Solo muestra el borde en móviles cuando está expandido
        }}
      >
        <Typography
          sx={{
            color: "#FFF",
            fontFamily: "Poppins",
            fontSize: isMobile ? "18px" : "20px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "140%",
            flex: "1 0 0",
          }}
        >
          {title}
        </Typography>
        {isMobile && (
          <Box
            component="img"
            src={
              expandedIndex === index
                ? themeMode.icons.flechaArriba
                : themeMode.icons.flechaAbajo
            }
            alt="toggle"
            sx={{
              width: "20px",
              height: "20px",
              transition: "transform 0.3s ease",
            }}
          />
        )}
      </Box>

      {/* Contenido del acordeón */}
      {expandedIndex === index && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          {items.map((item, itemIndex) => (
            <Box
              key={itemIndex}
              sx={{
                display: "flex",
                height: "40px",
                alignItems: "center",
                gap: "10px",
                alignSelf: "stretch",
              }}
            >
              <Typography
                sx={{
                  overflow: "hidden",
                  color: "#FFF",
                  textOverflow: "ellipsis",
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "16px",
                }}
              >
                {item}
              </Typography>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default Section;
