import React, { useState } from "react";
import { Box } from "@mui/material";
import InnerImageZoom from "react-inner-image-zoom";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css"; // Importa los estilos

interface Props {
  images: string[];
}

const ImageSlider: React.FC<Props> = ({ images }) => {
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const handleImageClick = (index: number) => {
    setSelectedIndex(index);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-start",
        gap: "20px",
      }}
    >
      {/* Opciones de miniaturas */}
      <Box
        sx={{
          display: "flex",
          width: "64px",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "4px",
        }}
      >
        {images.map((image, index) => (
          <Box
            key={index}
            onClick={() => handleImageClick(index)}
            sx={{
              cursor: "pointer",
              position: "relative",
              overflow: "hidden",
            }}
          >
            <Box
              component="img"
              src={image}
              alt={`Thumbnail ${index}`}
              sx={{
                width: "64px",
                height: "64px",
                objectFit: "cover",
                border: "1px solid #E2E5E8",
              }}
            />
            {/* Selector como barrita vertical */}
            {index === selectedIndex && (
              <Box
                sx={{
                  position: "absolute",
                  left: "0",
                  top: "0",
                  width: "2px",
                  height: "64px",
                  background: "#3677DE", // Color azul de la barra
                }}
              />
            )}
          </Box>
        ))}
      </Box>

      {/* Imagen principal con efecto lupa */}
      <Box
        sx={{
          position: "relative",
          width: "480px",
          border: "1px solid #E2E5E8",
          overflow: "hidden",
          borderRadius: "10px",
        }}
      >
        <InnerImageZoom
          src={images[selectedIndex]} // Imagen seleccionada
          zoomSrc={images[selectedIndex]} // Fuente para la imagen ampliada
          //zoomScale={1.6} // Escala del zoom
          zoomType="hover" // Tipo de zoom (hover o click)
        />
      </Box>
    </Box>
  );
};

export default ImageSlider;

