import { Box, Grid, Typography } from "@mui/material";
import { FC } from "react";
import { themes } from "../../../config/Icons";

interface Props {
  isDarkMode?: Boolean;
}

const Cliente: FC<Props> = ({ isDarkMode }) => {
  const themeMode = isDarkMode ? themes.dark : themes.light;
  return (
    <Box
      sx={{
        marginTop: "-120px", // Mueve el componente 120px hacia arriba
        position: "relative", // Asegura que el movimiento no afecte a otros elementos
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        alignSelf: "stretch",
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: {
            xs: "336px",
            sm: "616px",
            md: "616px",
            lg: "1200px",
            xl: "1200px",
          },
          padding:
          {
            xs: "32px 12px 24px 12px",
            sm: "32px 12px 24px 12px",
            md: "32px 12px 24px 12px",
            lg: "60px 12px 48px 12px",
            xl: "60px 12px 48px 12px",
          },
          
          flexDirection: "column",
          alignItems: "center",
          gap: "24px",
          borderRadius: "20px",
          background: "#FFF",
          boxShadow: "0px 4px 16px 0px rgba(30, 41, 71, 0.12)",
        }}
      >
        <Typography
          sx={{
            width: {
              xs: "296px",
              sm: "576px",
              md: "576px",
              lg: "800px",
              xl: "800px",
            },
            color: "#88919E",
            textAlign: "center",
            fontFamily: "montserrat",
            fontSize: {
              xs: "24px",
              sm: "24px",
              md: "24px",
              lg: "36px",
              xl: "36px",
            },
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "120%",
          }}
        >
          Socio tecnológico de confianza para más de {""}
          <Box
            component={"span"}
            sx={{
              color: "#42C67E",
            }}
          >
            1000 clientes {""}
          </Box>
          alrededor del mundo
        </Typography>

        {/*LOGOS */}
        <Grid
          sx={{ justifyContent: "center", alignItems: "" }}
          container
          columnGap={"12px"}
          rowGap={{ sm: "0px", md: "20px" }}
        >
          <Grid item>
            <Box
              sx={{
                width: { xs: "64px", sm: "64px", md: "96px" },
                height: { xs: "64px", sm: "64px", md: "96px" },
                backgroundImage: `url(${themeMode.icons.Maido})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
            />
          </Grid>
          <Grid item>
            <Box
              sx={{
                width: { xs: "64px", sm: "64px", md: "96px" },
                height: { xs: "64px", sm: "64px", md: "96px" },
                backgroundImage: `url(${themeMode.icons.Osaka})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
            />
          </Grid>
          <Grid item>
            <Box
              sx={{
                width: { xs: "64px", sm: "64px", md: "96px" },
                height: { xs: "64px", sm: "64px", md: "96px" },
                backgroundImage: `url(${themeMode.icons.Central})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
            />
          </Grid>
          <Grid item>
            <Box
              sx={{
                width: { xs: "102.6px", sm: "102.6px", md: "154px" },
                height: { xs: "64px", sm: "64px", md: "96px" },
                backgroundImage: `url(${themeMode.icons.Rafael})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
            />
          </Grid>
          <Grid item>
            <Box
              sx={{
                width: { xs: "102.6px", sm: "102.6px", md: "154px" },
                height: { xs: "64px", sm: "64px", md: "96px" },
                backgroundImage: `url(${themeMode.icons.Astrid})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
            />
          </Grid>
          <Grid item>
            <Box
              sx={{
                width: { xs: "64px", sm: "64px", md: "96px" },
                height: { xs: "64px", sm: "64px", md: "96px" },
                backgroundImage: `url(${themeMode.icons.Mercado})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
            />
          </Grid>
          <Grid item>
            <Box
              sx={{
                width: { xs: "64px", sm: "64px", md: "96px" },
                height: { xs: "64px", sm: "64px", md: "96px" },
                backgroundImage: `url(${themeMode.icons.Costanera})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
            />
          </Grid>

          <Grid item>
            <Box
              sx={{
                width: { xs: "64px", sm: "64px", md: "96px" },
                height: { xs: "64px", sm: "64px", md: "96px" },
                backgroundImage: `url(${themeMode.icons.Fiesta})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
            />
          </Grid>
          <Grid item>
            <Box
              sx={{
                width: { xs: "64px", sm: "64px", md: "96px" },
                height: { xs: "64px", sm: "64px", md: "96px" },
                backgroundImage: `url(${themeMode.icons.Amoramat})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
            />
          </Grid>
          <Grid item>
            <Box
              sx={{
                width: { xs: "64px", sm: "64px", md: "96px" },
                height: { xs: "64px", sm: "64px", md: "96px" },
                backgroundImage: `url(${themeMode.icons.Dunas})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
            ></Box>
          </Grid>
          <Grid item>
            <Box
              sx={{
                width: { xs: "64px", sm: "64px", md: "96px" },
                height: { xs: "64px", sm: "64px", md: "96px" },
                backgroundImage: `url(${themeMode.icons.Tallanes})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
            />
          </Grid>
          <Grid item>
            <Box
              sx={{
                width: { xs: "64px", sm: "64px", md: "96px" },
                height: { xs: "64px", sm: "64px", md: "96px" },
                backgroundImage: `url(${themeMode.icons.Roosevelt})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
            />
          </Grid>
          <Grid item>
            <Box
              sx={{
                width: { xs: "102.6Px", sm: "102.6px", md: "154px" },
                height: { xs: "64px", sm: "64px", md: "96px" },
                backgroundImage: `url(${themeMode.icons.Andeanwings})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
            />
          </Grid>
          <Grid item>
            <Box
              sx={{
                width: { xs: "154px", sm: "154px", md: "154px" },
                height: { xs: "96px", sm: "96px", md: "96px" },
                backgroundImage: `url(${themeMode.icons.Dmhotes})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
            />
          </Grid>
          <Grid item>
            <Box
              sx={{
                width: { xs: "102.6px", sm: "102.6px", md: "154px" },
                height: { xs: "64px", sm: "64px", md: "96px" },
                backgroundImage: `url(${themeMode.icons.Boulevard})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
            />
          </Grid>
          <Grid item>
            <Box
              sx={{
                width: { xs: "64px", sm: "64px", md: "96px" },
                height: { xs: "64px", sm: "64px", md: "96px" },
                backgroundImage: `url(${themeMode.icons.Tambo})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
            />
          </Grid>
          <Grid item>
            <Box
              sx={{
                width: { xs: "85.3px", sm: "85.3px", md: "128px" },
                height: { xs: "64px", sm: "64px", md: "96px" },
                backgroundImage: `url(${themeMode.icons.Clubcusco})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
            />
          </Grid>
          <Grid item>
            <Box
              sx={{
                width: { xs: "64px", sm: "64px", md: "96px" },
                height: { xs: "64px", sm: "64px", md: "96px" },
                backgroundImage: `url(${themeMode.icons.Britania})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Cliente;
