import { Box, Typography } from "@mui/material";
import React, { useState } from "react";

interface Props {
  title: string;
  Image: string;
  width?: string;
  height: string;
  descripcion: string;
  maxWidth?: any;
}

const CardModulos: React.FC<Props> = ({
  title,
  Image,
  width,
  maxWidth,
  height,
  descripcion,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: width, // Ancho completo del contenedor padre
        maxWidth: maxWidth,
        height: height,
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "8px",
        background: `url(${Image})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        position: "relative",
        overflow: "hidden",
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {/* Contenido principal */}
      <Box
        sx={{
          display: "flex",
          padding: "10px 20px",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "8px",
          flex: "1 0 0",
          alignSelf: "stretch",
          background: isHovered ? "" : "rgba(35, 35, 35, 0.50)",
        }}
      >
        <Box
          sx={{
            display: isHovered ? "none" : "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              alignSelf: "stretch",
              textAlign: "center",
              fontFamily: "Montserrat",
              fontSize: "24px",
              fontWeight: 500,
              lineHeight: "120%",
              color: "#FFFFFF",
            }}
          >
            {title}
          </Typography>
        </Box>
      </Box>

      {/* Fondo negro (aparece en hover) */}
      {isHovered && (
        <Box
          sx={{
            display: "flex",
            padding: "0px 20px",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "8px",
            flex: "1 0 0",
            alignSelf: "stretch",
            background: " rgba(35, 35, 35, 0.70)",
            position: "absolute", // Asegura que el fondo se superponga al contenido
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 1, // Asegura que el fondo esté encima del contenido
            cursor:"pointer"
          }}
        >
          {/* INFO */}
          <Box
            sx={{
              display: "flex",
              paddingTop: "20px",
              flexDirection: "column",
              alignItems: "center",
              gap: "8px",
              alignSelf: "stretch",
            }}
          >
            <Typography
              sx={{
                color: "#FFF",
                textAlign: "center",
                fontFamily: "Montserrat",
                fontSize: "24px",
                fontWeight: 500,
                lineHeight: "120%",
              }}
            >
              {title}
            </Typography>
            <Typography
              sx={{
                color: "#FFF",
                textAlign: "center",
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: 300,
                lineHeight: "150%",
              }}
            >
              {descripcion}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default CardModulos;
