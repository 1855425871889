import { Box, Typography } from "@mui/material";
import React, { FC } from "react";

interface Props {
  icon: string;
  backgroundColor?: string;
  label: string;
}

const HeaderIcon: FC<Props> = ({ icon, label }) => {
  return (
    <Box
      sx={{
        display: "flex",
        width: {
          xs: "68px",
          sm: "68px",
          md: "68px",
          lg: "100px",
          xl: "100px",
        },
        flexDirection: "column",
        alignItems: "center",
        gap: "8px",
      }}
    >
      <Box
        component="img"
        sx={{
          width: "28px",
          height: "28px",
          display: "flex",
          alignItems: "center",
          WebkitMaskImage: `url(${icon})`,
          WebkitMaskSize: "28px",
          WebkitMaskRepeat: "no-repeat",
          WebkitMaskPosition: "center",
          backgroundColor: "#3677DE",
        }}
      />
      <Typography
        sx={{
          color: "#1E2947",
          fontFamily: "Poppins",
          fontSize: {
            xs: "12px",
            sm: "12px",
            md: "12px",
            lg: "14px",
            xl: "14px",
          },
          fontStyle: "normal",
          fontWeight: 500,
          lineHeight: "16px",
        }}
      >
        {label}
      </Typography>
    </Box>
  );
};

export default HeaderIcon;
