import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { FC } from "react";
import ButtonText from "../../components/Buttones/ButtonText";
import { themes } from "../../config/Icons";
import { useLocation } from "react-router-dom";
import PropEquipo from "./PropEquipo";
import SolucionesEquipo from "./SolucionesEquipo";
import ImageSlider from "./ImageSlider";
interface Props {
  isDarkMode?: Boolean;
}

const DetallesEquipos: FC<Props> = ({ isDarkMode }) => {
  const themeMode = isDarkMode ? themes.dark : themes.light;
  const location = useLocation();
  const { card } = location.state || {}; // Acceso a los datos del componente

  const cardData = [
    {
      image: themeMode.icons.postac,
      title: "POS táctic y compacto",
    },
    {
      image: themeMode.icons.tamaño,
      title: 'Pantalla 10.1" 1280 x 800 px',
    },
    {
      image: themeMode.icons.group3,
      title: "Impresora térmica 3” 250 mm/s",
    },
    {
      image: themeMode.icons.procesador,
      title: "Procesador Intel",
    },
    {
      image: themeMode.icons.usb,
      title: "Multipuertos USB / COM / LAN",
    },
    {
      image: themeMode.icons.accesibilidad,
      title: "Fácil accesibilidad y mantenimiento",
    },
  ];

  const rows = [
    { title: "Método", describe: "Térmica Directa" },
    { title: "Velocidad", describe: "220 mm/s" },
    { title: "Resolución", describe: "203 dpi (8dots/mm)" },
    { title: "Ancho", describe: "72mm / 48mm" },
  ];

  const images = [
    themeMode.imagen.imagen1,
    themeMode.imagen.imagen2,
    themeMode.imagen.imagen3,
    themeMode.imagen.imagen4,
    themeMode.imagen.imagen6,
    themeMode.imagen.imagen7,
  ];

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        flexDirection: "column",
        alignItems: "center",
        background: "#F8F9FC",
      }}
    >
      {/*producto */}
      <Box
        sx={{
          display: "flex",
          width: "1200px",
          paddingTop: "100px",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "40px",
        }}
      >
        {/*contenido */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "20px",
            alignSelf: "stretch",
          }}
        >
          {/*BREADCRUMB */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                height: "48px",
                alignItems: "center",
                gap: "12px",
                cursor: "pointer",
              }}
            >
              <Typography
                sx={{
                  color: "#88919E",
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: 300,
                  lineHeight: "150%",
                }}
              >
                Inicio
              </Typography>
            </Box>
            {/*icon flecha */}
            <Box
              component={"img"}
              sx={{
                width: "24px",
                height: "24px",
                WebkitMaskImage: `url(${themeMode.icons.flechaDerecha})`,
                WebkitMaskSize: "24px",
                WebkitMaskRepeat: "no-repeat",
                WebkitMaskPosition: "center",
                backgroundColor: "#3677DE",
              }}
            />
            <Box
              sx={{
                display: "flex",
                height: "48px",
                alignItems: "center",
                gap: "12px",
                cursor: "pointer",
              }}
            >
              <Typography
                sx={{
                  color: "#88919E",
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: 300,
                  lineHeight: "150%",
                }}
              >
                Equipos AMC
              </Typography>
            </Box>
            {/*icon flecha */}
            <Box
              component={"img"}
              sx={{
                width: "24px",
                height: "24px",
                WebkitMaskImage: `url(${themeMode.icons.flechaDerecha})`,
                WebkitMaskSize: "24px",
                WebkitMaskRepeat: "no-repeat",
                WebkitMaskPosition: "center",
                backgroundColor: "#3677DE",
              }}
            />
            <Box
              sx={{
                display: "flex",
                height: "48px",
                alignItems: "center",
                gap: "12px",
                cursor: "pointer",
              }}
            >
              <Typography
                sx={{
                  color: "#2664C7",
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: 500,
                  lineHeight: "150%",
                }}
              >
                {card.title}
              </Typography>
            </Box>
          </Box>
          {/*CAJA */}
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              gap: "60px",
              alignSelf: "stretch",
            }}
          >
            {/*galeria */}
            <ImageSlider images={images} />

            {/*info */}
            <Box
              sx={{
                display: "flex",
                width: "576px",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "40px",
              }}
            >
              {/*cabecera */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: "40px",
                  alignSelf: "stretch",
                }}
              >
                {/*texto */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "24px",
                    alignSelf: "stretch",
                  }}
                >
                  {/*titulo */}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      gap: "8px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#1E2947",
                        fontFamily: "Montserrat",
                        fontSize: "28px",
                        fontWeight: 400,
                        lineHeight: "120%",
                      }}
                    >
                      {card.title}
                    </Typography>
                    {/*precios */}
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "20px",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#1E2947",
                          fontFamily: "Montserrat",
                          fontSize: "36px",
                          fontStyle: "normal",
                          fontWeight: 500,
                          lineHeight: "120%",
                        }}
                      >
                        {card.precio}
                      </Typography>
                      <Typography
                        sx={{
                          color: "#717883",
                          fontFamily: "Montserrat",
                          fontSize: "18px",
                          fontWeight: 400,
                          lineHeight: "120%",
                          textDecoration: "line-through",
                        }}
                      >
                        {card.descuento}
                      </Typography>
                    </Box>
                  </Box>
                  <Typography
                    sx={{
                      alignSelf: "stretch",
                      color: "#1E2947",
                      fontFamily: "Poppins",
                      fontSize: "16px",
                      fontWeight: 300,
                      lineHeight: "150%",
                    }}
                  >
                    {card.description}
                  </Typography>
                </Box>
                {/*boton texto */}
                <ButtonText
                  height="56px"
                  width="261px"
                  onClick={() => {}}
                  icon={themeMode.icons.mensaje}
                  title="!contáctanos¡"
                  active={true}
                  bgColor={"#4884E2"}
                  hoverColor={"#69C4DE"}
                  iconHcolor={"#FFFFFF"}
                  iconNcolor={"#FFFFFF"}
                  fontSize={15}
                />
              </Box>
              {/*propiedades */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  alignContent: "flex-start",
                  alignSelf: "stretch",
                  flexWrap: "wrap",
                }}
              >
                {/*Prop*/}
                {cardData.map((card, index) => (
                  <PropEquipo key={index} icon={card.image} text={card.title} />
                ))}
              </Box>
            </Box>
          </Box>
        </Box>

        {/*tecnica */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "20px",
            alignSelf: "stretch",
          }}
        >
          <Typography
            sx={{
              alignSelf: "stretch",
              color: "#1E2947",
              fontFamily: "Montserrat",
              fontSize: "24px",
              fontWeight: 400,
              lineHeight: "120%",
            }}
          >
            Especificaciones técnicas
          </Typography>
          {/*Tabla */}
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      color: "#1E2947",
                      fontFamily: "Poppins",
                      fontSize: "16px",
                      fontWeight: 500,
                      lineHeight: "150%",
                    }}
                  >
                    Impresión
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow
                    key={row.title}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      sx={{
                        color: "#1E2947",
                        fontFamily: "Poppins",
                        fontSize: "16px",
                        fontWeight: 300,
                        lineHeight: "150%",
                      }}
                    >
                      {" "}
                      {row.title}
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#1E2947",
                        fontFamily: "Poppins",
                        fontSize: "16px",
                        fontWeight: 300,
                        lineHeight: "150%",
                      }}
                    >
                      {row.describe}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>

              {/**separcion de tabla  */}
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      color: "#1E2947",
                      fontFamily: "Poppins",
                      fontSize: "16px",
                      fontWeight: 500,
                      lineHeight: "150%",
                    }}
                  >
                    Fuente de Impresión
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow
                    key={row.title}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      sx={{
                        color: "#1E2947",
                        fontFamily: "Poppins",
                        fontSize: "16px",
                        fontWeight: 300,
                        lineHeight: "150%",
                      }}
                    >
                      {" "}
                      {row.title}
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#1E2947",
                        fontFamily: "Poppins",
                        fontSize: "16px",
                        fontWeight: 300,
                        lineHeight: "150%",
                      }}
                    >
                      {row.describe}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>

              {/**separcion de tabla  */}
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      color: "#1E2947",
                      fontFamily: "Poppins",
                      fontSize: "16px",
                      fontWeight: 500,
                      lineHeight: "150%",
                    }}
                  >
                    Media
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow
                    key={row.title}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      sx={{
                        color: "#1E2947",
                        fontFamily: "Poppins",
                        fontSize: "16px",
                        fontWeight: 300,
                        lineHeight: "150%",
                      }}
                    >
                      {" "}
                      {row.title}
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#1E2947",
                        fontFamily: "Poppins",
                        fontSize: "16px",
                        fontWeight: 300,
                        lineHeight: "150%",
                      }}
                    >
                      {row.describe}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      {/*soluciones */}
      <SolucionesEquipo />
    </Box>
  );
};

export default DetallesEquipos;
