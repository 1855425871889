import React, { useState } from "react";
import { Box, TextField, Typography } from "@mui/material";

interface Props {
  label: string;
  titleInput: string;
  valueInput?: string;
  nameValue?: string;
  onChange: (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  showLogin?: boolean;
  error?: boolean;
  required: boolean;
  maxLength?: string;
  minLength?: string;
  value: any;
  defaultValue?: string | null;
  color: any;
  colorhover: any;
}

const TextInputCustom: React.FC<Props> = ({
  label,
  titleInput,
  nameValue,
  onChange,
  error,
  maxLength,
  minLength,
  value,
  defaultValue,
  color,
  colorhover,
}: Props) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <TextField
        variant="standard"
        id="outlined-multiline-static"
        multiline
        label={value ? label : ""}
        placeholder={titleInput}
        name={nameValue}
        value={value}
        rows={2}
        defaultValue={defaultValue}
        onChange={onChange}
        {...(error && { error: true, helperText: error })}
        InputProps={{
          disableUnderline: true,
        }}
        InputLabelProps={{
          shrink: value ? true : false,
        }}
        inputProps={{
          maxLength: maxLength,
          minLength: minLength,
        }}
        sx={{
          display: "flex",
          height: "auto",
          minHeight: "76px",
          overflowY: "auto",
          gap: "var(--radio-sm, 12px)",
          alignSelf: "stretch",
          "&.MuiInputBase-input": {
            paddingBottom: "10px",
          },
          "&.MuiFormControl-root": {
            borderRadius: "4px",
            border: isHovered && !value ? `1px solid ${color}` : `1px solid var(--neutralgrisclaro, #E2E5E8)`,
            background: "var(--color-neutral-50-C, #FFF)",
            backgroundColor: isHovered && !value ? (colorhover) : "var(--color-neutral-50-C, #FFF)",
            boxShadow: isHovered && !value ? "none" : "0px 4px 12px 0px rgba(30, 41, 71, 0.08)",
          },
          "& .MuiFormLabel-root": {
            marginTop: "-6px",
            paddingTop: value !== "" ? "20px" : "0px",
            paddingLeft: "14px",
            fontFamily: "Poppins",
            fontWeight: value !== "" ? 500 : 300,
            fontSize: {
              xs: "14px",
              sm: "14px",
              md: "16px",
              lg: "16px",
              xl: "16px",
            },
            lineHeight: {
              xs: "20px",
              sm: "20px",
              md: "24px",
              lg: "24px",
              xl: "24px",
            },
            color:
              value !== ""
                ? "var(--colorPrimary-Black)"
                : "var(--colorPrimary-Gray)",
            "&.Mui-focused": {
              fontSize: {
                xs: "14px",
                sm: "14px",
                md: "16px",
                lg: "16px",
                xl: "16px",
              },
              lineHeight: {
                xs: "14px",
                sm: "14px",
                md: "18px",
                lg: "18px",
                xl: "18px",
              },
              color: "var(--colorPrimary-Black)",
              fontFamily: "Poppins",
              fontWeight: 500,
              paddingLeft: "14px",
              paddingTop: value !== "" ? "23px" : "23px",
            },
          },
          "& .MuiInputBase-root": {
            fontSize: {
              xs: "12px",
              sm: "12px",
              md: "14px",
              lg: "14px",
              xl: "14px",
            },
            lineHeight: {
              xs: "14px",
              sm: "14px",
              md: "18px",
              lg: "18px",
              xl: "18px",
            },
            color: "var(--colorPrimary-Black)",
            fontFamily: "Poppins",
            fontWeight: 300,
            paddingTop: value !== "" ? "10px" : "0px",
            paddingLeft: "12px",
            marginTop:"12px",
            "& ::placeholder": {
              fontFamily: "Poppins",
              fontWeight: 300,
              fontSize: {
                xs: "14px",
                sm: "14px",
                md: "16px",
                lg: "16px",
                xl: "16px",
              },
              lineHeight: {
                xs: "20px",
                sm: "20px",
                md: "24px",
                lg: "24px",
                xl: "24px",
              },
              color: "var(--colorPrimary-Gray)",
            },
          },
        }}
      />
      <Typography
        fontFamily={"Poppins"}
        fontSize={"12px"}
        fontWeight={500}
        alignItems="center"
        sx={{
          color: "var(--colorPrimary-Error)",
          display: error === true ? "flex" : "none",
          fontSize: "12px",
          fontFamily: "Poppins",
          fontWeight: "500",
          lineHeight: {
            xs: "16px",
            sm: "16px",
            md: "18px",
            lg: "18px",
            xl: "18px",
          },
          marginTop: "5px",
        }}
      >
        {error}
      </Typography>
    </Box>
  );
};

export default TextInputCustom;
