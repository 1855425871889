import { Box, Typography } from "@mui/material";
import { FC } from "react";
import { themes } from "../../../config/Icons";

interface Props {
  isDarkMode?: Boolean;
}

const Optimizamos: FC<Props> = ({ isDarkMode }) => {
  const themeMode = isDarkMode ? themes.dark : themes.light;
  return (
    <Box
      sx={{
        display: "flex",
        padding: {
          xs: "16px",
          sm: "60px 12px 20px 12px",
          md: "60px 12px 20px 12px",
          lg: "100px 10px 20px 10px",
          xl: "100px 10px 20px 10px",
        },

        justifyContent: "center",
        alignItems: "flex-start",
        alignSelf: "stretch",
      }}
    >
      {/* INFO */}
      <Box
        sx={{
          display: "flex",
          width: "500px",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "40px",
        }}
      >
        {/* Texto */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "28px",
            alignSelf: "stretch",
          }}
        >
          {/* Título */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "24px",
              alignSelf: "stretch",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Montserrat",
                fontSize: {
                  xs: "24px",
                  sm: "24px",
                  md: "24px",
                  lg: "36px",
                  xl: "36px",
                },
                fontWeight: 500,
                lineHeight: "120%",
              }}
            >
              Optimizamos la gestión de tu negocio
            </Typography>
            {/* Separador */}
            <Box component={"img"} src={themeMode.icons.separador} />
          </Box>
          <Typography
            sx={{
              alignSelf: "stretch",
              fontFamily: "poppins",
              fontSize: "16px",
              fontWeight: 300,
              lineHeight: "150%",
            }}
          >
            En Infomática nos enfocamos en plantear soluciones expertas en
            productos de software y servicios, para garantizar la excelencia de
            tu negocio en el mercado HORECA.
          </Typography>
        </Box>
        {/* Innovar */}

        <Box
          sx={{
            display: {
              xs: "none",
              sm: "none",
              md: "none",
              lg: "flex",
              xl: "flex",
            },
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "12px",
          }}
        >
          {/* Contenedor de texto rotado */}
          <Box
            sx={{
              display: "flex",
              minHeight: "226px",
            }}
          >
            <Typography
              sx={{
                color: "#88919E",
                fontFamily: "Montserrat",
                fontSize: "20px",
                fontWeight: 400,
                lineHeight: "120%",
                letterSpacing: "2px",
                transform: "rotate(-90deg)",
              }}
            >
              ¡QUIERO INNOVAR!
            </Typography>
          </Box>

          {/* Flecha */}
          <Box
            component={"img"}
            src={themeMode.icons.fechaLinealAbajo}
            sx={{
              width: "24px",
              height: "40px",
              alignSelf: "flex-start",
              marginTop: "12px",
            }}
          />
        </Box>
      </Box>

      {/* LOGO DE PRODUCCION */}
      <Box
        component={"img"}
        src={themeMode.imagen.producto}
        sx={{
          width: {
            xs: "322.5px",
            sm: "322.5px",
            md: "322.5px",
            lg: "649.967px",
            xl: "649.967px",
          },
          height: {
            xs: "300px",
            sm: "300px",
            md: "300px",
            lg: "600px",
            xl: "600px",
          },
        }}
      />
    </Box>
  );
};

export default Optimizamos;
